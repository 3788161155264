import Vue from "vue";
import Loading from "./Loading.vue";

const Mask = Vue.extend(Loading);

const toggleLoading = (el, binding) => {
  if (binding.value) {
    Vue.nextTick(() => {
      if (binding.modifiers.fullScreen) {
        insertDom(document.body, el, binding);
      } else {
        insertDom(el, el, binding);
      }
    });
  } else {
    el.instance.visible = false;
    el.domVisible = false;
  }
};

const insertDom = (parent, el) => {
  if (!el.domVisible) {
    el.domVisible = true;
    parent.appendChild(el.mask);
    el.instance.visible = true;
    el.domInserted = true;
  } else if (el.domVisible && el.instance.hiding === true) {
    el.instance.visible = true;
  }
};

Vue.directive("loading", {
  inserted: function () {},
  bind: function (el, binding) {
    const mask = new Mask({
      el: document.createElement("div"),
      data: {
        fullScreen: binding.modifiers.fullscreen,
      },
    });
    el.instance = mask;
    el.mask = mask.$el;
    binding.value && toggleLoading(el, binding);
  },
  update: function (el, binding) {
    if (binding.oldValue != binding.value) {
      toggleLoading(el, binding);
    }
  },
  unbind: function (el, binding) {
    if (el.domInserted) {
      el.mask && el.mask.parentNode && el.mask.parentNode.removeChild(el.mask);
      toggleLoading(el, { value: false, modifiers: binding.modifiers });
    }
    el.instance && el.instance.$destroy();
  },
});
