var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-box" }, [
    _c(
      "span",
      { staticClass: "input-con" },
      [
        _c("Input", {
          staticClass: "custom-input",
          staticStyle: { width: "222px" },
          attrs: {
            prefix: "ios-search",
            placeholder: "请输入品类",
            clearable: "",
          },
          on: {
            "on-change": _vm.handleSearch,
            "on-enter": _vm.handleSearch,
            "on-focus": _vm.handleSearch,
          },
          model: {
            value: _vm.keyword,
            callback: function ($$v) {
              _vm.keyword = $$v
            },
            expression: "keyword",
          },
        }),
      ],
      1
    ),
    _vm.showPanel
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.closeList,
                expression: "closeList",
              },
            ],
            staticClass: "input-panel",
          },
          [
            _vm.searchList.length
              ? _c(
                  "ul",
                  { staticClass: "list" },
                  _vm._l(_vm.searchList, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: item.code,
                        on: {
                          click: function ($event) {
                            return _vm.selectItem(item, index)
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(item.name) },
                        }),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            !_vm.searchList.length && _vm.keyword
              ? _c("NoData", {
                  staticClass: "no-data",
                  attrs: { text: "暂无相关搜索，换个词试试" },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }