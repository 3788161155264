import Vue from "vue";
import Vuex from "vuex";
import query from "./query.js";
import { deepAssign } from "js/utils.js";

Vue.use(Vuex);
const cateList = [
  { tag: "all", sort: 0, label: "" },
  { tag: "location", sort: 1, label: "位置" },
  { tag: "brand", sort: 2, label: "品牌" },
  { tag: "mall", sort: 3, label: "商场" },
  { tag: "business", sort: 4, label: "商圈" },
  { tag: "city", sort: 5, label: "城市" },
];
let cateObj = {};
for (let i = 0, n = cateList.length; i < n; i++) {
  let item = cateList[i];
  cateObj[item.tag] = item;
}
let storeOptions = {
  state: {
    menus: [
      {
        name: "首页",
        path: "/index",
        cate: "all",
        alias: "全部",
        homeShow: true,
        placeholder: "请输入品牌、位置、商圈、商场、城市等",
        hotWords: ["品牌店分析", "客群画像", "区域周边业态", "客流趋势"]
      },
      {
        name: "查品牌",
        path: "/brand",
        cate: "brand",
        homeShow: true,
        placeholder: "请输入品牌名称、品牌类别",
        hotWords: ["门店分布", "销量趋势", "客群画像", "线上营销"]
      },
      {
        name: "查位置",
        path: "/location",
        cate: "location",
        homeShow: true,
        placeholder: "请输入具体位置",
        hotWords: ["周边配套", "客群特征", "业态分布"]
      },
      {
        name: "查商圈",
        path: "/business",
        cate: "business",
        homeShow: true,
        placeholder: "请输入商圈名称",
        hotWords: ["覆盖品牌", "到访客流", "配套业态", "周边客群"]
      },
      {
        name: "查商场",
        path: "/mall",
        cate: "mall",
        homeShow: true,
        placeholder: "请输入商场名称",
        hotWords: ["客流来源", "商户分析", "周边业态"]
      },
      {
        name: "查城市",
        path: "/city",
        cate: "city",
        homeShow: true,
        placeholder: "请输入城市名称",
        hotWords: ["综合经济", "商业布局", "交通出行", "人口热力"]
      }
      // {
      //   name: "数据市场",
      //   path: "/data_market",
      //   cate: "data_market",
      // },
    ],
    introductionList: [
      {
        key: "product",
        title: "产品服务",
        enTitle: "PRODUCT  SERVICE",
        desc: "品牌、门店、区域、商圈、商场、城市等商业数据搜索引擎"
      },
      {
        key: "data",
        title: "数据能力",
        enTitle: "DATA CAPABILITY",
        desc: "整合GIS、人工智能、时空数据、品牌数据、短视频创意营销等领域的能力，为企业提供数据查询、分析和对比等服务，帮助企业进行发展和决策"
      },
      {
        key: "industry",
        title: "行业方案",
        enTitle: "INDUSTRY SOLUTIONS",
        desc: "为行业提供营销分析、定制运营策略和开拓市场等数据的辅助决策方案"
      },
      {
        key: "case",
        title: "客户案例",
        enTitle: "CUSTOMER CASES",
        desc: "众多行业，数百家企业与政府的共同选择"
      }
    ],
    token: "",
    visitorUid: "",
    isUserLogin: false,
    isVIP: false,
    userInfo: {},
    isUpgrade:false
  },
  mutations: {
    clearToken(state) {
      state.token = "";
      state.isUserLogin = false;
      localStorage.removeItem("token");
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      state.isUserLogin = true;
    },
    setVisitorUid(state, visitorUid) {
      state.visitorUid = visitorUid;
      localStorage.setItem("visitorUid", visitorUid);
    },
    clearVisitorUid(state) {
      state.token = "";
      localStorage.removeItem("visitorUid");
    },
    setUserInfoByKey(state, kv) {
      if (!state.userInfo[kv[0]]) {
        Vue.set(state.userInfo, kv[0], kv[1]);
      } else {
        state.userInfo[kv[0]] = kv[1];
      }
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    setUserInfo(state, data) {
      state.userInfo = data;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    }
  },
  actions: {},
  modules: {},
  getters: {
    getToken: (state) => () => {
      let token = state.token;
      if (!token) {
        token = localStorage.getItem("token") || "";
      }
      return token;
    },
    getVisitorUid: (state) => () => {
      let visitorUid = state.visitorUid;
      if (!visitorUid) {
        visitorUid = localStorage.getItem("visitorUid") || "";
      }
      return visitorUid;
    },
    getSortId: (state) => (tag) => {
      let item = cateObj[tag];
      return item.sort;
    },
    getUserInfo: (state) => {
      let userInfo = state.userInfo;
      if (!userInfo || Object.keys(userInfo).length <= 0) {
        try {
          userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
        } catch (e) {
          userInfo = {};
        }
        state.userInfo = userInfo;
      }
      return userInfo;
    }
  }
};
storeOptions = deepAssign({}, storeOptions, query);
export default new Vuex.Store(storeOptions);
