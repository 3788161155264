<template>
  <div class="input-box">
    <span class="input-con">
      <Input v-model="keyword"
             prefix="ios-search"
             placeholder="请输入品类"
             style="width: 222px;"
             clearable
             @on-change="handleSearch"
             @on-enter="handleSearch"
             @on-focus="handleSearch"
             class="custom-input"
      >
      </Input>
    </span>
    <div class="input-panel" v-if="showPanel" v-click-outside="closeList">
      <ul v-if="searchList.length" class="list">
        <li v-for="(item, index) of searchList" :key="item.code" @click="selectItem(item, index)">
          <span v-html="item.name"></span>
        </li>
      </ul>
      <NoData v-if="!searchList.length && keyword" class="no-data" text="暂无相关搜索，换个词试试"></NoData>
    </div>
  </div>
</template>

<script>
import { GetCategorySearch } from '@/service';
export default {
  name: "BrandSearch",
  data () {
    return {
      keyword: '',
      searchList: [],
      showPanel: false
    }
  },
  watch: {
    showPanel (val) {
      if (!val) {
        this.keyword = ''
      }
    },
    keyword (val) {
      this.$emit('getSearchKey', val)
    }
  },
  methods: {
    closeList () {
      this.showPanel = false
    },
    selectItem (item, index) {
      console.log('selectItem', item);
      this.keyword = ''
      this.$emit('getVal', item, index)
      this.closeList()
    },
    handleSearch(e) {
      if (!this.keyword) {
        this.showPanel = false;
        this.searchList = [];
        return;
      }
      // this.isShowList = true;
      let cName = e.target.value;
      if (this.keyword == cName) {
        this.getSearchList();
      }
    },
    async getSearchList () {
      const res = await GetCategorySearch({ word: this.keyword })
      console.log('getSearchList', res);
      if (res.code === 200) {
        this.showPanel = true;
        const data = res.data?.items || []
        const newData = data.map(item => {
          const reg = new RegExp(this.keyword, 'g')
          const replaceStr = `<span style="color:#3519FB">${this.keyword}</span>`
          item.name = item.name.replace(reg, replaceStr)
          return item
        })
        this.searchList = newData
        console.log(555);
      }

    }
  }
}
</script>

<style lang="less" scoped>
.custom-input {
  /deep/ .ivu-input-icon {
    cursor: pointer;
  }
}
.input-con {
  padding: 0 20px;
}
.input-box {
  padding: 20px 0;
  position: relative;
  min-height: 50px;
}
.input-panel {
  background: #fff;
  border-radius: 4px;
  z-index: 1;
  margin-top: 10px;
  .list {
    font-size: 14px;
    margin-bottom: 10px;
    max-height: 200px;
    overflow-y: auto;
    & > li {
      margin-top: 8px;
      padding: 4px 20px;
      cursor: pointer;
      &:hover {
        background: rgba(53, 25, 251, 0.06);
      }
    }
  }
}
</style>