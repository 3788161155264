import Vue from "vue";
import Store from "@/store";
import EditEmail from "./EditEmail.vue";
import EditPhone from "./EditPhone.vue";
import EditPwd from "./EditPwd.vue";
import EditWx from "./EditWx.vue";

const EditEmailCon = Vue.extend(EditEmail);
EditEmailCon.prototype.close = function () {
  if (this.$el && this.$el.parentNode) {
    this.$el.parentNode.removeChild(this.$el);
  }
  this.close();
};
const EditPhoneCon = Vue.extend(EditPhone);
EditPhoneCon.prototype.close = function () {
  if (this.$el && this.$el.parentNode) {
    this.$el.parentNode.removeChild(this.$el);
  }
  this.close();
};
const EditPwdCon = Vue.extend(EditPwd);
EditPwdCon.prototype.close = function () {
  if (this.$el && this.$el.parentNode) {
    this.$el.parentNode.removeChild(this.$el);
  }
  this.close();
};
const EditWxCon = Vue.extend(EditWx);
EditWxCon.prototype.close = function () {
  if (this.$el && this.$el.parentNode) {
    this.$el.parentNode.removeChild(this.$el);
  }
  this.close();
};

//编辑邮箱
let editEmailIns = null;
const editEmail = (callback) => {
  if (editEmailIns && editEmailIns.isOpen) {
    return editEmailIns;
  }
  let parent = document.body;
  let instance = new EditEmailCon({
    el: document.createElement("div"),
    propsData: {
      email: Store.state.userInfo.email,
      mode: "edit",
      userName: Store.state.userInfo.userName,
    },
  });
  parent.appendChild(instance.$el);
  instance.isOpen = true;
  instance.callback = callback;
  editEmailIns = instance;
  return instance;
};
editEmail.close = () => {
  if (editEmailIns && editEmailIns.isOpen) {
    editEmailIns.close();
  }
};

//绑定邮箱
let bindEmailIns = null;
const bindEmail = (callback) => {
  if (bindEmailIns && bindEmailIns.isOpen) {
    return bindEmailIns;
  }
  let parent = document.body;

  let instance = new EditEmailCon({
    el: document.createElement("div"),
    propsData: {
      email: Store.state.userInfo.email,
      mode: "bind",
      userName: Store.state.userInfo.userName,
    },
  });
  parent.appendChild(instance.$el);
  instance.isOpen = true;
  instance.callback = callback;
  bindEmailIns = instance;
  return instance;
};
bindEmail.close = () => {
  if (bindEmailIns && bindEmailIns.isOpen) {
    bindEmailIns.close();
    bindEmailIns = null;
  }
};

//编辑手机号
let editPhoneIns = null;
const editPhone = (phone) => {
  if (editPhoneIns && editPhoneIns.isOpen) {
    return editPhoneIns;
  }
  let parent = document.body;
  let instance = new EditPhoneCon({
    el: document.createElement("div"),
    propsData: { phone: phone, mode: "edit" },
  });
  parent.appendChild(instance.$el);
  instance.isOpen = true;
  editPhoneIns = instance;
  return instance;
};
editPhone.close = () => {
  if (editPhoneIns && editPhoneIns.isOpen) {
    editPhoneIns.close();
    editPhoneIns = null;
  }
};

//绑定手机号
let bindPhoneIns = null;
const bindPhone = () => {
  if (bindPhoneIns && bindPhoneIns.isOpen) {
    return bindPhoneIns;
  }
  let parent = document.body;
  let instance = new EditPhoneCon({
    el: document.createElement("div"),
    propsData: { phone: "", mode: "bind" },
  });
  parent.appendChild(instance.$el);
  instance.isOpen = true;
  bindPhoneIns = instance;
  return instance;
};
bindPhone.close = () => {
  if (bindPhoneIns && bindPhoneIns.isOpen) {
    bindPhoneIns.close();
    bindPhoneIns = null;
  }
};

//修改密码
let editPwdIns = null;
const editPwd = () => {
  if (editPwdIns && editPwdIns.isOpen) {
    return editPwdIns;
  }
  let parent = document.body;
  let instance = new EditPwdCon({
    el: document.createElement("div"),
    propsData: { phone: "", mode: "bind" },
  });
  parent.appendChild(instance.$el);
  instance.isOpen = true;
  editPwdIns = instance;
  return instance;
};
editPwd.close = () => {
  if (editPwdIns && editPwdIns.isOpen) {
    editPwdIns.close();
    editPwdIns = null;
  }
};

//绑定微信
let bindWxIns = null;
const bindWx = (callback) => {
  if (bindWxIns && bindWxIns.isOpen) {
    return bindWxIns;
  }
  let parent = document.body;
  let instance = new EditWxCon({
    el: document.createElement("div"),
    propsData: { phone: Store.state.userInfo.phone, mode: "bind" },
  });
  parent.appendChild(instance.$el);
  instance.isOpen = true;
  instance.callback = callback;
  bindWxIns = instance;
  return instance;
};
bindWx.close = () => {
  if (bindWxIns && bindWxIns.isOpen) {
    bindWxIns.close();
    bindWxIns = null;
  }
};

export { editEmail, bindEmail, editPhone, bindPhone, editPwd, bindWx };
