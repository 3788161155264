import Vue from "vue";
import axios from "axios";
import { Message } from "view-design";
import Store from "../store/index.js";

const errorMsg = "网络有误，稍后再试";
// 请求超时时间
axios.defaults.timeout = 600000;

// post请求头
axios.defaults.headers.post["Content-Type"] = "application/json";

// 请求拦截器
axios.interceptors.request.use(
  async (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const token = window.sessionStorage.getItem("token");
    const token = Store.getters.getToken();
    if (token) {
      config.headers.uid = "";
      config.headers.Authorization = token;
    } else {
      const visitorUid = Store.getters.getVisitorUid();
      config.headers.uid = visitorUid;
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
let modalInstance = null;
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response.data);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        // 401: 未登录
        case 401:
          Vue.prototype.$global.confirm({
            title: "友情提示",
            content: "登录超时，请重新登录",
            noCancle: true,
            onOk: () => {
              Vue.prototype.$global.doLogout &&
                Vue.prototype.$global.doLogout();
            },
          });
          break;
      }
      return Promise.reject(error.response.data);
    } else {
      Vue.prototype.$Message.error("接口请求异常!");
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        if (res.code !== 200) {
          Message.error(res.message || res.msg || errorMsg);
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, _options) {
  return new Promise((resolve, reject) => {
    //  axios.post(url, QS.stringify(params))
    let options = _options || {};
    axios
      .post(url, params, options)
      .then((res) => {
        // 新增全局 code 20002 拦截，对于角色查看详情的限制提示信息进行过滤
        if (res.code !== 200 && res.code !== 20002) {
          Message.error(res.message || res.msg || errorMsg);
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function put(url, params) {
  return new Promise((resolve, reject) => {
    //  axios.post(url, QS.stringify(params))
    axios
      .put(url, params)
      .then((res) => {
        if (res.code !== 200) {
          Message.error(res.message || res.msg || errorMsg);
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function del(url, params) {
  return new Promise((resolve, reject) => {
    //  axios.post(url, QS.stringify(params))
    axios
      .delete(url, {
        data: params,
      })
      .then((res) => {
        if (res.code !== 200) {
          Message.error(res.message || res.msg || errorMsg);
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
