import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = require("./routes");
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
let routePaths = routes.map((item) => item.path);
// console.log(routePaths);
router.beforeEach((to, from, next) => {
  /*eslint-disable */
  console.log('beforeEach', to, from);
  if (!to.path.includes("/vip")) {
    document.getElementsByTagName("body")[0].style.zoom = 1;
  }
  if (routePaths.includes(to.path)) {
    next();
  } else if (String(to.path).split("/")[1] == "doc") {
    next();
  } else {
    next("/");
  }
});

export default router;
