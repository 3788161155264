export default {
  state: {
    tabsDetailFields: {
      // community: ["小区名称", "均价（元/㎡）", "楼栋数"], //小区详情列表字段
      community: ["小区名称", "均价（元/㎡）", "geom"], //小区详情列表字段
      office: ["写字楼名称", "平均租金(元/m²·天)", "占地面积(㎡)"], //写字楼详情列表字段,元/m²·天
      business: ["商业名称", "地址", "类型"], //商业详情列表字段
      businessTable2: ["名称", "地址"], //商业详情列表字段
      school: ["学校名称", "类型"], //学校详情列表字段
      hospital: ["医院名称", "医院类型"], //医院详情列表字段
      company: ["公司企业名称", "类型"], //公司详情列表字段
      foodBeverage: ["名称", "地址"], //业态-美食餐饮
      lifeService: ["名称", "地址"], //业态-生活服务
      hotel: ["名称", "地址"], //业态-宾馆酒店
      medical: ["名称", "地址"], //业态-医疗卫生
      sports: ["名称", "地址"], //业态-体育休闲
      bank: ["名称", "地址"], //业态-金融银行
      bus: ["名称", "地址/线路"], //交通-公交
      subway: ["名称", "地址/线路"], //交通-地铁
      parkingLot: ["名称", "地址/线路"], //交通-停车场
      chargingPile: ["名称", "地址/线路"], //交通-充电桩
    },
    // 位置poi字段
    tabsLocationFields: {
      // community: ["小区名称", "均价", "楼栋数"], //小区详情列表字段
      community: ["小区名称", "均价"], //小区详情列表字段
      office: ["写字楼名称", "平均租金", "占地面积"], //写字楼详情列表字段
      business: ["名称", "地址"], //商业详情列表字段
      school: ["名称", "类型"], //学校详情列表字段
      hospital: ["名称", "类型"], //医院详情列表字段
      company: ["名称", "类型", "地址"], //公司详情列表字段
      foodBeverage: ["名称", "地址"], //业态-美食餐饮
      lifeService: ["名称", "地址"], //业态-生活服务
      hotel: ["名称", "地址"], //业态-宾馆酒店
      medical: ["名称", "地址"], //业态-医疗卫生
      sports: ["名称", "地址"], //业态-体育休闲
      bank: ["名称", "地址"], //业态-金融银行
      bus: ["名称", "经过线路"], //交通-公交
      subway: ["名称", "经过线路"], //交通-地铁
      parkingLot: ["名称", "地址"], //交通-停车场
      chargingPile: ["名称", "地址"], //交通-充电桩
    },
    tabsIdentify: {
      community: "sg-data-商场-商场区位分析-周边配套信息-小区-详情", //小区详情列表字段
      office: "sg-data-商场-商场区位分析-周边配套信息-写字楼-详情", //写字楼详情列表字段
      business:
        "sg-data-商场-商场区位分析-周边配套信息-商业-超市/便利店详细信息", //商业详情列表字段
      school: "sg-data-商场-商场区位分析-周边配套信息-学校-详情", //学校详情列表字段
      hospital: "sg-data-商场-商场区位分析-周边配套信息-医院-详情", //医院详情列表字段
      company: "sg-data-商场-商场区位分析-周边配套信息-公司企业-详情", //公司详情列表字段
      foodBeverage: "sg-data-商场-业态详情分布-详情", //美食餐饮
      lifeService: "sg-data-商场-业态详情分布-详情", //生活服务
      hotel: "sg-data-商场-业态详情分布-详情", //宾馆酒店
      medical: "sg-data-商场-业态详情分布-详情", //医疗卫生
      sports: "sg-data-商场-业态详情分布-详情", //体育休闲
      bank: "sg-data-商场-业态详情分布-详情", //金融银行
      bus: "sg-data-商场-周边交通-详情", //交通-公交
      subway: "sg-data-商场-周边交通-详情", //交通-地铁
      parkingLot: "sg-data-商场-周边交通-详情", //交通-停车场
      chargingPile: "sg-data-商场-周边交通-详情", //交通-充电桩
    },
    tabsIdentify2: {
      community: "sg-data-商场-商场区位分析-周边配套信息-小区",
      office: "sg-data-商场-商场区位分析-周边配套信息-写字楼",
      business: "sg-data-商场-商场区位分析-周边配套信息-商业",
      school: "sg-data-商场-商场区位分析-周边配套信息-学校",
      hospital: "sg-data-商场-商场区位分析-周边配套信息-医院",
      company: "sg-data-商场-商场区位分析-周边配套信息-公司企业",
      store: "sg-data-商场-商场区位分析-周边配套信息-商业-便利店分析",
      traffic: "sg-data-商场-周边交通",
      format: "sg-data-商场-业态详情分布",
    },
    tabsIdentifyBusiness: {
      community: "sg-data-商圈-资源配套-小区-详情", //小区详情列表字段
      office: "sg-data-商圈-资源配套-写字楼-详情", //写字楼详情列表字段
      business: "sg-data-商圈-资源配套-商业-详情", //商业详情列表字段
      school: "sg-data-商圈-资源配套-学校-详情", //学校详情列表字段
      hospital: "sg-data-商圈-资源配套-医院-详情", //医院详情列表字段
      company: "sg-data-商圈-资源配套-公司企业-详情", //公司详情列表字段

      foodBeverage: "sg-data-商圈-业态分布-详情", //美食餐饮
      lifeService: "sg-data-商圈-业态分布-详情", //生活服务
      hotel: "sg-data-商圈-业态分布-详情", //宾馆酒店
      medical: "sg-data-商圈-业态分布-详情", //医疗卫生
      sports: "sg-data-商圈-业态分布-详情", //体育休闲
      bank: "sg-data-商圈-业态分布-详情", //金融银行

      bus: "sg-data-商圈-交通状况-详情", //交通-公交
      subway: "sg-data-商圈-交通状况-详情", //交通-地铁
      parkingLot: "sg-data-商圈-交通状况-详情", //交通-停车场
      chargingPile: "sg-data-商圈-交通状况-详情", //交通-充电桩

      GDP: "sg-data-城市-GDP", //综合经济
      income: "sg-data-城市-商圈-综合经济", //综合经济
      expend: "sg-data-城市-商圈-综合经济", //综合经济
      retail: "sg-data-城市-商圈-综合经济", //综合经济
    },
    tabsIdentifyBusiness2: {
      community: "sg-data-商圈-资源配套-小区", //小区详情列表字段
      office: "sg-data-商圈-资源配套-写字楼", //写字楼详情列表字段
      business: "sg-data-商圈-资源配套-商业", //商业详情列表字段
      school: "sg-data-商圈-资源配套-学校", //学校详情列表字段
      hospital: "sg-data-商圈-资源配套-医院", //医院详情列表字段
      company: "sg-data-商圈-资源配套-公司企业", //公司详情列表字段
      store: "sg-data-商圈-资源配套-商业-便利店分析", //超市/便利店分析
      traffic: "sg-data-商圈-交通状况", //交通状况
      format: "sg-data-商圈-业态分布",
    },
    tabsIdentifyLocation: {
      community: "sg-data-位置-小区详细信息", //小区详情列表字段
      office: "sg-data-位置-写字楼详细信息", //写字楼详情列表字段
      business: "sg-data-位置-商业详细信息", //商业详情列表字段
      school: "sg-data-位置-各学校详细信息", //学校详情列表字段
      hospital: "sg-data-位置-医院详细信息", //医院详情列表字段
      company: "sg-data-位置-企业详细信息", //公司详情列表字段

      foodBeverage: "sg-data-位置-美食餐饮", //美食餐饮
      lifeService: "sg-data-位置-生活服务", //生活服务
      hotel: "sg-data-位置-宾馆酒店", //宾馆酒店
      medical: "sg-data-位置-医疗卫生", //医疗卫生
      sports: "sg-data-位置-体育休闲", //体育休闲
      bank: "sg-data-位置-金融银行", //金融银行

      bus: "sg-data-位置-公交", //交通-公交
      subway: "sg-data-位置-地铁", //交通-地铁
      parkingLot: "sg-data-位置-停车场", //交通-停车场
      chargingPile: "sg-data-位置-充电桩", //交通-充电桩
    },
  },
};
