<template>
  <div class="no-data-wrap">
    <div class="no-data">
      <img :src="img" alt="" />
      <div class="">{{ text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      default: "/images/no-data.png",
    },
    text: {
      type: String,
      default: "暂无数据",
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.no-data-wrap {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 200px;
      height: 124px;
    }
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.24);
  }
}
</style>
