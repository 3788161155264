var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app", attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("Loading"),
      _vm.checkLogin() && !_vm.showMonthlyCard ? _c("MonthlyCard") : _vm._e(),
      _c("ModalUpgrade", {
        attrs: { isShow: _vm.isShow },
        on: {
          close: function ($event) {
            _vm.isShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }