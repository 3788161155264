const assign = require("lodash/assign");
const defaultConfig = require("./config.default.js");

const config = assign({}, defaultConfig, {
  proxyHost: "/",
  loginUrl: "https://penelope-test.changjing.com.cn",
  appId: "wxc297ecd19a71a006",
  encypt: false,
});

module.exports = config;
