<template>
  <div v-if="isOpen">
    <Modal
      :value="isOpen"
      class-name="modal-pay-info"
      width="400"
      :closable="true"
      :mask-closable="false"
      footer-hide
      @on-visible-change="handleChangeVisible"
    >
      <div class="upgrade-wrap">
        <p class="upgrade-title">升级您的版本</p>
        <div class="upgrade-message">
          获得更多品牌&商圈&商场信息、多维度客群画像分析能力、多指标深度分析高级报告、更多其他功能
        </div>
        <Form
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="80"
        >
          <FormItem label="姓名" prop="name">
            <Input
              v-model="formValidate.name"
              placeholder="请输入您的姓名"
            ></Input>
          </FormItem>
          <FormItem label="手机" prop="phone">
            <Input
              v-model="formValidate.phone"
              placeholder="请输入手机号码"
            ></Input>
          </FormItem>
          <FormItem label="行业" prop="industry">
            <Input
              v-model="formValidate.industry"
              placeholder="请输入所在行业"
            ></Input>
          </FormItem>
          <FormItem label="留言" prop="message">
            <Input
              v-model="formValidate.message"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 7 }"
              placeholder="使用目的、公司名称、使用部门、预算区间..."
            ></Input>
          </FormItem>
          <div class="submit-btn-wrap">
            <Button class="submit-btn" @click="handleSubmit('formValidate')"
              >立即咨询</Button
            >
          </div>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import { User } from "@/service";
export default {
  props: ["isShow"],
  name: "upgrade",
  data() {
    return {
      isOpen: false,
      formValidate: {
        name: "",
        phone: "",
        industry: "",
        message: ""
      },
      ruleValidate: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式错误",
            trigger: "blur"
          }
        ],
        industry: [
          {
            required: true,
            message: "请输入所在行业",
            trigger: "blur"
          }
        ],
        message: [
          {
            required: false,
            message: "请输入备注",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    isShow(val) {

      this.isOpen = val;

    },
    isOpen(newval) {
      if (!newval) {
        this.close();
      }
    },

  },
  methods: {

    close() {
      this.$emit("close");

    },
    handleChangeVisible(tag) {
      this.$refs["formValidate"].resetFields();
      if (!tag) {
        this.close();
      }
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.submit(this.formValidate);
        }
      });
    },

    async submit(data) {
      let res = await User.upgrade(data);
      if (res && res.code === 200) {
        this.$Message.success("提交成功");
        this.handleChangeVisible();
      } else {
        this.$Message.error("提交失败");
      }
    }
  },
  mounted() {}
};
</script>
<style lang="less">
.modal-pay-info {
  display: flex;
  justify-content: center;
  align-items: center;
  .ivu-modal {
    top: 0 !important;
  }
  // .ivu-modal .ivu-modal-content {
  //   width: 400px;
  //   // height: 306px;
  //   background: #fff;
  //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  //   // border-radius: 2px;
  //   border: 1px solid rgba(56, 76, 108, 0.8);
  //   backdrop-filter: blur(16px);
  // }
  .ivu-modal-body {
    padding: 30px;
    .upgrade-title {
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #3519fb;
      line-height: 40px;
      text-align: center;
    }
    .upgrade-message {
      // width:300px ;
      font-size: 14px;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 25px;
      margin-top: 15px;
    }
    .ivu-form {
      .submit-btn-wrap {
        text-align: center;
      }
      .submit-btn {
        margin: 0 auto;

        border: none;
        border-radius: 3px;

        font-size: 14px;
        font-weight: 400;

        border: none;
        background: #3519fb;
        color: #ffffff;

      }
    }
  }
}
</style>
