<template>
  <div class="edit-email" v-if="isOpen">
    <div class="mask"></div>
    <div class="dialog">
      <div class="header">
        <span class="title">{{ title }}</span>
        <img
          class="close-icon"
          src="@/assets/images/icon/close@2x.png"
          alt=""
          @click="close"
        />
      </div>
      <div class="content">
        <div class="item" v-show="mode === 'bind'">
          <div class="input-wrap2">
            <input
              type="text"
              class="fw-input"
              v-model="oldEmail"
              v-focusblur
              placeholder="请输入邮箱"
            />
          </div>
        </div>

        <div class="item" v-show="statusEdit === 1 && mode === 'edit'">
          <span class="txt1">已绑定邮箱：{{ oldEmail }}</span>
        </div>

        <div class="item" v-show="statusEdit !== 1 && mode === 'edit'">
          <div class="input-wrap2">
            <input
              type="text"
              class="fw-input"
              v-model="newEmail"
              v-focusblur
              placeholder="请输入邮箱"
            />
          </div>
        </div>

        <div class="item">
          <div class="input-wrap">
            <input
              type="text"
              class="ver-code"
              v-model="verCode"
              v-focusblur
              placeholder="请输入邮箱验证码"
            />
            <img
              v-show="verCode"
              src="@/assets/images/icon/icon_clear.png"
              alt=""
              @click="verCode = ''"
              class="close-input-icon"
            />
          </div>
          <span
            class="ver-code-time"
            :class="{
              'no-hover': timer !== 0,
              'light-ver-code': emailRule() && timer === 0,
            }"
            @click="getVerCode"
            >{{ hintTxt }}</span
          >
        </div>
      </div>
      <div class="footer">
        <div class="btn" @click="btnHandler">
          <span class="main-btn" v-show="statusEdit === 1 && mode === 'edit'"
            >下一步</span
          >
          <span
            class="main-btn"
            v-show="(statusEdit !== 1 && mode === 'edit') || mode === 'bind'"
            >确定</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { emailReg } from "../../../assets/javascript/utils";
import { User } from "../../../service";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "editEmail",
  props: {
    mode: {
      type: String,
      required: true,
      default: "edit", //edit bind 修改或者绑定
    },
    email: {
      type: String,
      required: true,
      default: "",
    },
    userName: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      statusEdit: 1, //验证手机号状态
      title: "绑定邮箱",
      newEmail: "",
      oldEmail: "",
      verCode: "",
      timer: 0,
      hintTxt: "获取验证码",
      callback: null,
    };
  },
  methods: {
    ...mapMutations(["setUserInfoByKey"]),
    btnHandler() {
      if (!this.verCode) {
        this.$Message.error("请输入邮箱验证码!");
        return;
      }
      if (this.mode === "bind") {
        if (this.verCode) {
          this.checkEmailCode(this.oldEmail, this.verCode);
        }
      } else if (this.mode === "edit") {
        if (this.statusEdit === 1) {
          if (this.verCode) {
            this.checkEmailCode(this.oldEmail, this.verCode);
          }
        } else if (this.statusEdit === 2) {
          this.checkEmailCode(this.newEmail, this.verCode);
        }
      }
    },
    getVerCode() {
      if (this.timer !== 0) return;
      let bool = false;
      let tempEmail = "";
      if (this.mode === "edit" && this.statusEdit === 2) {
        bool = emailReg.test(this.newEmail);
        tempEmail = this.newEmail;
      } else {
        bool = emailReg.test(this.oldEmail);
        tempEmail = this.oldEmail;
      }
      if (!bool) {
        this.$Message.error("请输入正确的邮箱号码！");
        return;
      }

      if (this.timer === 0) {
        this.verCode = "";
        this.sendEmailCode(tempEmail);
      }
    },
    emailRule() {
      if (this.mode === "edit" && this.statusEdit !== 1) {
        return emailReg.test(this.newEmail);
      } else {
        return emailReg.test(this.oldEmail);
      }
    },
    async sendEmailCode(email) {
      const params = {
        email: email,
      };
      const res = await User.getEmailCaptcha(params);
      if (res && res.code === 200) {
        this.$Message.info("发送成功！");
        this.countDown();
      }
    },
    async checkEmailCode(email, code) {
      const params = {
        code: code + "",
        email: email,
      };
      const res = await User.checkEmailCaptcha(params);
      if (res && res.code === 200) {
        this.clearTimer();
        if (this.mode === "edit") {
          if (this.statusEdit === 1) {
            this.verCode = "";
            this.statusEdit = 2;
          } else if (this.statusEdit === 2) {
            this.updateEmail(this.newEmail);
          }
        } else if (this.mode === "bind") {
          //请求修改接口
          this.updateEmail(this.oldEmail);
        }
      } else {
        this.clearTimer();
      }
    },

    //修改用户邮箱
    async updateEmail(email) {
      let params = {
        userName: this.userName,
        emailCode: this.verCode,
        email: email,
      };
      const res = await User.updateUserInfo(params);
      if (res && res.code === 200) {
        this.$Message.success("修改成功!");
        if (this.callback) {
          this.callback(email);
          this.close();
        }
      }
    },
    //倒计时
    countDown() {
      let countNum = 600;
      this.timer = setInterval(() => {
        countNum -= 1;
        this.hintTxt = `已发送（${countNum}s）`;
        if (countNum <= 0) {
          this.clearTimer();
        }
      }, 1000);
    },
    //清除定时器
    clearTimer() {
      clearInterval(this.timer);
      this.timer = 0;
      this.hintTxt = "获取验证码";
    },
    initTitle() {
      // console.log(this.mode)
      if (this.mode === "edit") {
        this.title = "修改邮箱";
      } else {
        this.title = "绑定邮箱";
      }
    },
    close() {
      this.isOpen = false;
      this.clearTimer();
    },
  },
  created() {
    this.initTitle();
    this.oldEmail = this.email;
  },
  mounted() {},
  destroyed() {
    this.clearTimer();
  },
};
</script>
<style lang="less" scoped>
@import "./edit.less";
.edit-email {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
