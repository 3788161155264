import Vue from "vue";
import CryptoJS from "crypto-js";
import { get, post, put, del } from "./axios.js";
import { globalVipDialog } from "../assets/javascript/globalVipDialog";

/**
 * AES加密
 * @param {any} word 加密数据
 */
export const cryptoEncrypt = (word) => {
  var key = CryptoJS.enc.Utf8.parse(Vue.envConfig.aesKey);
  var iv = CryptoJS.enc.Utf8.parse(Vue.envConfig.aesIv);
  var encrypted = "";
  var srcs = "";
  if (typeof word == "string") {
    srcs = word;
  } else if (typeof word == "object") {
    //对象格式的转成json字符串
    srcs = CryptoJS.enc.Utf8.parse(word);
  }
  encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs5,
  });
  return encrypted.ciphertext.toString();
};
/**
 * AES解密
 * @param {any} word 解密数据
 */
export const cryptoDecrypt = (word) => {
  if (!word) {
    return word;
  }
  var key = CryptoJS.enc.Utf8.parse(Vue.envConfig.aesKey);
  var iv = CryptoJS.enc.Utf8.parse(Vue.envConfig.aesIv);
  // var iv = Vue.envConfig.aesIv;
  var encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    // padding: CryptoJS.pad.Pkcs5,
  });
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  let str = decryptedStr.toString();
  return JSON.parse(str);
};

/**
 * 获取数据
 * @param { string } tag 名称
 * @param { string } type 请求接口方式
 */
export const getDataByParams = function (tag, type, _options) {
  return async (params) => {
    let isEncypt = false;
    let isBlob = false;
    let tarPathArr = tag.split(".");
    let url = Vue.envConfig.serverUrls;
    for (let i = 0; i < tarPathArr.length; i++) {
      url = url[tarPathArr[i]];
    }
    if (!url && tag.indexOf(".") > -1) {
      let arr = tag.split(".");
      let obj = Vue.envConfig.serverUrls;
      for (let i = 0, n = arr.length; i < n; i++) {
        let key = arr[i];
        if (!obj[key] && typeof obj === "string") {
          break;
        }
        obj = obj[key];
      }
      if (typeof obj === "string") {
        url = obj;
      }
    }
    let options = _options || {};
    if (options.encypt) {
      isEncypt = true;
    } else if (options.blob) {
      isBlob = true;
    }
    try {
      let res;
      let funs = {
        get: get,
        post: post,
        put: put,
        del: del,
      };
      let fun = funs[type];
      if (isBlob) {
        let postOptions = {
          responseType: "blob",
        };
        fun = async (url, params) => {
          return await post(url, params, postOptions);
        };
      }
      res = await fun(url, params);
      if (res && res.code === 200) {
        if (isEncypt) {
          let data2 = res.data;
          data2 = cryptoDecrypt(data2);
          res.data = data2;
        }
        return res;
      }
      // 全局处理角色可查看模块详情的到限引导提示框
      if (res && res.code === 20002) {
        const isLogin = localStorage.getItem("token") ? true : false;
        globalVipDialog(isLogin);
      }
      return null;
    } catch (error) {
      // console.log("error");
    }
  };
};

/**
 * 获取json文件
 */
export const getJsonFile = async (url) => {
  try {
    if (url.indexOf("?") === -1) {
      url += "?t=" + new Date().getTime();
    } else {
      url += "&t=" + new Date().getTime();
    }
    let res = await get(url);
    if (res && res.data) {
      return res.data;
    }
    return null;
  } catch (error) {
    // handleError(error);
  }
};

export const getFile = async (url) => {
  try {
    if (url.indexOf("?") === -1) {
      url += "?t=" + new Date().getTime();
    } else {
      url += "&t=" + new Date().getTime();
    }
    let res = await get(url);
    return res;
  } catch (error) {
    // handleError(error);
  }
};
