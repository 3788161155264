<template>
  <div
    class="header-wrap"
    :class="{
      'showdow-ss header-wrap-height-mode2': mode === 2,
      'header-wrap-height-mode1 header-wrap-mode1': mode === 1,
    }"
  >
    <div
      class="header-top"
      :class="{
        'header-top-mode1': mode === 1,
        'header-top-mode2': mode === 2,
      }"
    >
      <div class="logo-wrap" @click="toHome">
        <img
          class="logo logo-white"
          src="../assets/images/logo-white.png"
          alt=""
        />
        <img class="logo logo-blue" src="../assets/images/logo.png" alt="" />
        <span class="beta-icon"></span>
      </div>
      <div class="header-search-panel" v-if="mode === 2">
        <SearchPanel
          :cate="curMenuCate"
          :searchWords="searchKey"
          :placeholder="placeholder"
          :cityCode="cityCode"
          @search="search"
        ></SearchPanel>
      </div>
      <div class="header-menus" v-if="mode === 1">
        <div
          v-for="(item, index) in menus"
          :key="'menu_' + index"
          :class="{ cur: curMenuCate === item.cate }"
          @click="gotoPath(item.path)"
        >
          <a
            :class="{
              'header-menus-color-mode1': mode === 1,
              'header-menus-color-mode2': mode === 2,
            }"
            >{{ item.name }}</a
          >
        </div>
      </div>
      <div class="login-btns" v-show="!getToken()">
        <div class="header-toolbar">
          <div >
            <div @mouseenter="openMenu" @mouseleave.stop="closeMenu">
              <template v-if="mode === 1 && !isRevertIcon">
                <template v-if="navBarFixed">
                  <img
                    :src="
                      require('@/assets/images/icon/relation-app-revert-icon.png')
                    "
                    width="30"
                    height="30"
                  /><span>应用</span>
                </template>
                <template v-else>
                  <img
                    :src="require('@/assets/images/icon/relation-app-icon.png')"
                    width="30"
                    height="30"
                  /><span>应用</span>
                </template>
              </template>
              <template v-if="mode === 2 || isRevertIcon">
                <img
                  :src="
                    require('@/assets/images/icon/relation-app-revert-icon.png')
                  "
                  width="30"
                  height="30"
                /><span>应用</span>
              </template>
            </div>
            <div
              class="relation-app"
              v-show="visibleRelationApp"
              @mouseenter="openMenu"
              @mouseleave.stop="closeMenu"
            >
              <section v-for="item of relationList" :key="item.title">
                <h3>{{ item.title }}</h3>
                <div class="layout-flex">
                  <figure
                    v-for="itm of item.data"
                    :key="itm.title"
                    @click="goToTargetLink(itm)"
                  >
                    <img :src="itm.icon" width="36" height="36" />
                    <figcaption>
                      <h4>{{ itm.title }}</h4>
                      <p>{{ itm.description }}</p>
                    </figcaption>
                  </figure>
                </div>
              </section>
            </div>
          </div>
          <!-- <div @click="gotoVip">
            <img
              :src="require('@/assets/images/icon/member-icon.png')"
              width="30"
              height="26"
            /><span>会员</span>
          </div> -->
        </div>
        <span @click="doLogin" class="login-btn">登录</span>/<span
          @click="doLogin"
          class="register-btn"
          >注册</span
        >
      </div>
      <div class="login-btns g-aleady-login" v-show="getToken()">
        <div class="header-toolbar">
          <!-- class="g-margin-right" -->
          <div >
            <div @mouseenter="openMenu" @mouseleave.stop="closeMenu">
              <template v-if="mode === 1 && !isRevertIcon">
                <template v-if="navBarFixed">
                  <img
                    :src="
                      require('@/assets/images/icon/relation-app-revert-icon.png')
                    "
                    width="30"
                    height="30"
                  /><span>应用</span>
                </template>
                <template v-else>
                  <img
                    :src="require('@/assets/images/icon/relation-app-icon.png')"
                    width="30"
                    height="30"
                  /><span>应用</span>
                </template>
              </template>
              <template v-if="mode === 2 || isRevertIcon">
                <img
                  :src="
                    require('@/assets/images/icon/relation-app-revert-icon.png')
                  "
                  width="30"
                  height="30"
                /><span>应用</span>
              </template>
            </div>
            <div
              class="relation-app"
              v-show="visibleRelationApp"
              @mouseenter="openMenu"
              @mouseleave.stop="closeMenu"
            >
              <section v-for="item of relationList" :key="item.title">
                <h3>{{ item.title }}</h3>
                <div class="layout-flex">
                  <figure
                    v-for="itm of item.data"
                    :key="itm.title"
                    @click="goToTargetLink(itm)"
                  >
                    <img :src="itm.icon" width="36" height="36" />
                    <figcaption>
                      <h4>{{ itm.title }}</h4>
                      <p>{{ itm.description }}</p>
                    </figcaption>
                  </figure>
                </div>
              </section>
            </div>
          </div>
          <!-- <div @click="gotoVip">
            <img
              :src="require('@/assets/images/icon/member-icon.png')"
              width="30"
              height="26"
            /><span>会员</span>
          </div> -->
        </div>
        <Avatar :mode="mode" />
      </div>
    </div>
    <div class="header-menus header-menus-navs" v-show="mode === 2">
      <div class="menus-navs">
        <div
          v-for="(item, index) in menus"
          :key="'menu_' + index"
          :class="{ cur: curMenuCate === item.cate }"
          @click="gotoPath(item.path)"
        >
          <a class="header-menus-color-mode2">{{ item.name }}</a>
        </div>
      </div>
      <div class="data-update-time">数据更新日期: {{ yesterday }}</div>
    </div>
    <DatePicker
      v-if="isShowDate"
      class="header-date-serch"
      type="daterange"
      placeholder="按日期范围筛选"
      :options="dateOptions"
      placement="bottom-end"
      separator=" 至 "
      style="width: 240px"
      @on-change="changeDaterange"
    ></DatePicker>
  </div>
</template>

<script>
import SearchPanel from "./SearchPanel.vue";
import Avatar from "./user/Avatar.vue";
import { login, timeFormat } from "../assets/javascript/utils";
import { mapGetters } from "vuex";
import { openNewWindow } from "@/assets/javascript/utils.js";
import mdxzIcon from "@/assets/images/icon/mdxz-icon.png";
import yjztIcon from "@/assets/images/icon/yjzt-icon.png";
import mddcIcon from "@/assets/images/icon/mddc-icon.png";
import yjpbIcon from "@/assets/images/icon/yjpb-icon.png";
import yjfdIcon from "@/assets/images/icon/yjfd-icon.png";
import stIcon from "@/assets/images/icon/st-icon.png";
import qlIcon from "@/assets/images/icon/ql-icon.png";
import syIcon from "@/assets/images/icon/sy-icon.png";
import xxlIcon from "@/assets/images/icon/xxl-icon.png";
import bdshIcon from "@/assets/images/icon/bdsh-icon.png";
import cpzsIcon from "@/assets/images/icon/cpzs-icon.png";
import gjhIcon from "@/assets/images/icon/gjh-icon.png";
import tvcIcon from "@/assets/images/icon/tvc-icon.png";
import ysdhIcon from "@/assets/images/icon/ysdh-icon.png";
import mbdzIcon from "@/assets/images/icon/mbdz-icon.png";
export default {
  props: {
    mode: {
      //mode:1 单行头部行高55px mode:2带搜索,2行头部高度101
      type: Number,
      default: 1,
    },
    navBarFixed: {
      type: Boolean,
    },
    isRevertIcon: {
      type: Boolean,
      default: false,
    },
    // 是否显示日期选择
    isShowDate: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
      default: "",
    },
    cityCode: {
      type: String,
      default: "",
    },
    detailType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      path: "",
      visibleRelationApp: false,
      relationList: Object.freeze([
        {
          title: "线下洞察",
          data: [
            {
              title: "亿景智图",
              description: "基于时空大数据的位置智能服务平台",
              link: "https://zt.changjing.com.cn/",
              icon: yjztIcon,
            },
            {
              title: "门店选址",
              description: "智能化一键选址决策平台",
              link: "https://xz.changjing.com.cn/",
              icon: mdxzIcon,
            },
            {
              title: "门店洞察",
              description: "门店客群行为画像分析平台",
              link: "https://zk.changjing.com.cn/",
              icon: mddcIcon,
            },
            {
              title: "亿景排班",
              description: "智能化外勤排班管理系统",
              link: "https://pb.changjing.com.cn/",
              icon: yjpbIcon,
            },
            {
              title: "亿景分单",
              description: "精准分拣物流订单服务平台",
              link: "https://fd.changjing.com.cn/",
              icon: yjfdIcon,
            },
          ],
        },
        {
          title: "线上营销",
          data: [
            {
              title: "闪投",
              description: "跨媒体智能广告投放平台",
              link: "https://www.clipworks.com/product/ad",
              icon: stIcon,
            },
            {
              title: "启量",
              description: "商业短视频创意生产管理平台",
              link: "https://www.clipworks.com/product/creative",
              icon: qlIcon,
            },
            {
              title: "瞬意",
              description: "短视频智能创意一站式平台",
              link: "https://v.clipworks.com/",
              icon: syIcon,
            },
          ],
        },
        {
          title: "创意服务",
          data: [
            {
              title: "信息流短视频",
              description: "全媒体信息流广告视频服务",
              link: "https://promo.clipworks.com/",
              icon: xxlIcon,
            },
            {
              title: "本地生活服务",
              description: "为商家提供到店拍摄一站式服务",
              link: "https://promo.clipworks.com/",
              icon: bdshIcon,
            },
            {
              title: "产品展示视频",
              description: "电商产品优质展示视频",
              link: "https://promo.clipworks.com/",
              icon: cpzsIcon,
            },
            {
              title: "国际化视频",
              description: "兼具国外特色的专业出海创意服务",
              link: "https://promo.clipworks.com/",
              icon: gjhIcon,
            },
            {
              title: "TVC广告片",
              description: "品质化高端商业广告宣传片",
              link: "https://promo.clipworks.com/",
              icon: tvcIcon,
            },
            {
              title: "影视动画",
              description: "高品质2D、3D动画制作",
              link: "https://promo.clipworks.com/",
              icon: ysdhIcon,
            },
            {
              title: "模板定制",
              description: "海量模板视频个性化定制服务",
              link: "https://promo.clipworks.com/",
              icon: mbdzIcon,
            },
          ],
        },
      ]),
      defaultUserImg: require("@/assets/images/user_default.png"),
      // 数据更新日期
      yesterday: timeFormat(new Date(), "Y/m/d"),
      // 日期组件配置
      dateOptions: {
        // 不可选日期范围
        disabledDate(date) {
          return (
            date &&
            (date.valueOf() > Date.now() ||
              date.valueOf() < new Date("2019-12-31"))
          );
        },
        // 快捷选项
        shortcuts: [
          {
            text: "近7天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "近30天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "近90天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
    isHomePage() {
      return this.path === "" || this.path === "/";
    },
    menus() {
      return this.$store.state.menus;
    },
    // 当前页面大类
    curMenuCate() {
      let path = this.path;
      for (let i = 0, n = this.menus.length; i < n; i++) {
        let menu = this.menus[i];
        if (
          path === menu.path ||
          (menu.cate && menu.cate !== "all" && path.includes(menu.cate))
        ) {
          return menu.cate;
        }
      }
      if (path.includes("store")) {
        return "brand";
      }
      return "none";
    },
    placeholder() {
      let cate = this.curMenuCate;
      let item = this.menus.filter((item) => item.cate === cate);
      return item[0].placeholder;
    },
  },
  watch: {
    "$route.path"() {
      this.initPath();
    },
    isShowDate(newVal) {
      if (!newVal) {
        this.changeDaterange([]);
      }
    },
  },
  methods: {
    initData() {},
    initPath() {
      this.path = this.$route.path;
    },
    openMenu() {
      this.timer && clearTimeout(this.timer);
      this.visibleRelationApp = true;
    },
    closeMenu() {
      this.timer = setTimeout(() => {
        this.visibleRelationApp = false;
      }, 500);
    },
    goToTargetLink({ link }) {
      window.open(link, "_blank");
    },
    gotoPath(path) {
      this.$router.push(path);
    },
    doLogin() {
      login();
    },
    gotoVip() {
      this.$router.push({ path: "/vip" });
    },
    // 日期值变化事件
    changeDaterange(date) {
      this.$emit("changeDaterange", date);
    },
    search(item) {
      //2022.10.19 Z.R.X处理详情页搜索跳转
      if (this.detailType === "品牌") {
        //this.$router.push("/brand?key="+item+"");
        openNewWindow("/brand", {
          key: item,
        });
        return item;
      } else if (this.detailType === "商圈") {
        //this.$router.push("/business?key="+item+"");
        openNewWindow("/business", {
          key: item,
        });
        return item;
      } else if (this.detailType === "商场") {
        //this.$router.push("/mall?key="+item+"");
        openNewWindow("/mall", {
          key: item,
        });
        return item;
      } else if (this.detailType === "城市") {
        //this.$router.push("/city?key="+item+"");
        openNewWindow("/city", {
          key: item,
        });
        return item;
      }
      this.$emit("search", item);
    },
    toHome() {
      this.$router.push("/");
    },
  },
  created() {
    this.initPath();
  },
  mounted() {
    this.initData();
    let user = localStorage.getItem("userInfo");
    this.userInfo = JSON.parse(user);
  },
  components: {
    SearchPanel,
    Avatar,
  },
};
</script>

<style lang="less" scoped>
.header-toolbar {
  display: inline-block;
  margin-right: 30px;
  div {
    display: inline-block;
  }
  img {
    vertical-align: middle;
  }
  .g-margin-right {
    position: relative;
    margin-right: 30px;
  }
  span {
    vertical-align: middle;
    margin-left: 4px;
  }
  .relation-app {
    padding: 20px 15px;
    width: 775px;
    background: rgba(255, 255, 255, 0.99);
    box-shadow: 0px 1px 17px 6px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(16px);
    position: absolute;
    top: calc(100% + 10px);
    z-index: 99999;
    transform: translateX(-80%);
    h3 {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }
    h3::before {
      content: "";
      position: relative;
      top: 1px;
      display: inline-block;
      margin-right: 4px;
      width: 3px;
      height: 14px;
      background: #3519fb;
      border-radius: 2px;
    }
    figure {
      display: flex;
      width: 248px;
      height: 55px;
      margin-bottom: 15px;
      padding: 9px 7px;
      border-radius: 2px;
    }
    figure:hover {
      background: rgba(82, 76, 226, 0.04);
    }
    .layout-flex {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
    figcaption {
      margin-left: 6px;
      line-height: 1.5;
      h4 {
        font-weight: 400;
        color: #000000;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
.header-toolbar:hover {
  cursor: pointer;
}
.g-aleady-login {
  display: flex;
  align-items: center;
}
.header-date-serch {
  /deep/input:not([disabled]) {
    border-color: #dadada;
    box-shadow: none;
  }
  /deep/ .ivu-input:hover {
    border-color: #3519fb;
  }
  /deep/ .ivu-date-picker-cells-cell-range:before {
    background: rgba(53, 25, 251, 0.04);
  }
  /deep/ .ivu-date-picker-cells-cell:hover em {
    background: rgba(53, 25, 251, 0.04);
  }
  /deep/ .ivu-date-picker-cells-cell-selected em,
  /deep/ .ivu-date-picker-cells-cell-selected:hover em {
    background: #3519fb;
  }
  /deep/ .ivu-date-picker-cells-focused em {
    box-shadow: 0 0 0 1px #3519fb inset;
  }
  /deep/ .ivu-date-picker-cells-cell-today em:after {
    background: #3519fb;
  }
}
</style>
