import { getDataByParams } from "./base.js";
import Store from "../store/index.js";

export const User = {
  addCollection: getDataByParams("addCollection", "post"), //收藏
  deleteCollection: getDataByParams("deleteCollection", "del"), //取消收藏
  getCollStatus: getDataByParams("getCollStatus", "post"), //查看是否被收藏
  getCollectionList: getDataByParams("getCollectionList", "get"), //获取收藏列表
  checkCollectStatus: async (sortId, codes) => {
    // let sortId = Store.getters.getSortId(tag);
    let items = [];
    for (let i = 0, n = codes.length; i < n; i++) {
      let code = codes[i];
      items.push({
        code: code,
        sort: sortId
      });
    }
    let params = {
      items: items
    };
    let res = await User.checkCollectStatus2(params);
    return res;
  },
  checkCollectStatus2: getDataByParams("checkCollectStatus", "post"), // 检测多个项目是否被收藏
  getUserInfo: getDataByParams("getUserInfo", "get"), //获取用户信息
  uploadUserAvatar: getDataByParams("uploadImg", "post"), //上传用户头像
  updateUserInfo: getDataByParams("updateUserInfo", "put"), //更新用户头像/昵称/邮箱
  getSmsCaptcha: getDataByParams("getSmsCaptcha", "post"), //获取用户手机验证码
  checkSmsCaptche: getDataByParams("checkSmsCaptche", "post"), //验证手机号验证码
  bindWechat: "", //
  upDatePwd: getDataByParams("upDatePwd", "post"), //密码更新
  getImgCaptcha: getDataByParams("getImgCaptcha", "get"), //获取图形验证码
  verImgCaptcha: "", //
  checkUser: "", //
  getEmailCaptcha: getDataByParams("getEmailCaptcha", "post"), //发送邮箱验证码
  checkEmailCaptcha: getDataByParams("checkEmailCaptcha", "post"), //验证邮箱
  bindLoginWx: getDataByParams("bindLoginWx", "post"), //绑定、登录微信

  getBrandList: getDataByParams("getBrandList", "post"), //获取品牌认领列表
  setBrandClaim: getDataByParams("setBrandClaim", "post"), //获取品牌认领列表
  cancelBrandClaim: getDataByParams("cancelBrandClaim", "post"), //取消认领
  brandAudit: getDataByParams("brandAudit", "post"), //获取品牌认领列表
  getBrandClaimSmsCaptche: getDataByParams("getBrandClaimSmsCaptche", "post"), //获取品牌认领列表
  getBrandStatus: getDataByParams("getBrandStatus", "post"), //获取认领状态

  vipAsk: getDataByParams("vipAsk", "post"), //VIP咨询
  getOrderList: getDataByParams("oriderList", "post"),
  getOrderQrcode: getDataByParams("getOrderQrcode", "post"),
  getOrderStatus: getDataByParams("getOrderStatus", "post"),
  setClaimvip: getDataByParams("setClaimvip", "post"),
  upgrade: getDataByParams("upgrade", "post")
};
