<template>
  <div>
    <Modal class="custom-model" v-model="modal" :mask-closable="false">
      <span class="close-btn" @click="close"></span>
      <div>
        <!-- <img width="562px" height="472px" src="../assets/images/vip-month.png" alt=""> -->
        <img
          width="562px"
          height="472px"
          src="../assets/images/vip-month2.png"
          alt=""
        />
        <div class="btn-group">
          <div class="tab-btn" @click="confirm">立即领取</div>
          <div class="tootl-btn" @click="noShow">不再提示</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { User } from "@/service";
export default {
  name: "monthlyCard",
  data() {
    return {
      modal: true
    };
  },
  methods: {
    close() {
      this.modal = false;
    },
    confirm() {
      this.claimVip(false);
      this.close();
    },
    noShow() {
      this.claimVip(true);
      this.close();
    },
    async claimVip(giveUp) {
      const uesr = JSON.parse(localStorage.getItem("userInfo"));
      const res = await User.setClaimvip({ userID: +uesr.userID, giveUp });
      if (res.code === 200) {
        const data = await User.getUserInfo();
        localStorage.setItem("userInfo", JSON.stringify(data.data.items));
        this.$store.commit("setUserInfo", data.data.items);
        this.$Message.success(res.data);
      }
      return res;
    }
  }
};
</script>

<style lang="less" scoped>
.custom-model {
  /deep/ .ivu-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  /deep/ .ivu-modal-footer,
  /deep/ .ivu-modal-close {
    display: none;
  }
  position: relative;
  .close-btn {
    display: inline-block;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 25px;
    right: -58px;
    cursor: pointer;
    background: url("../assets/images/vip-close.png") no-repeat;
    background-size: 100%;
    &:hover {
      background: url("../assets/images/vip-close-hover.png") no-repeat;
      background-size: 100%;
    }
  }
  .btn-group {
    text-align: center;
    position: absolute;
    bottom: 47px;
    left: 181px;
  }
  .tab-btn {
    cursor: pointer;
    width: 236px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    // background: linear-gradient(315deg, #D9A36B 0%, #F8DBAF 100%);
    background: linear-gradient(134deg, #8c92b7 0%, #43496e 100%);
    box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);
    border-radius: 4px;
    // color: #6A3611;

    color: #faeed8;
    font-weight: 600;
    font-size: 16px;
    &:hover {
      // color: #522302;
      // background: linear-gradient(315deg, #D99651 0%, #FFD89D 100%);
      background: linear-gradient(134deg, #707595 0%, #303553 100%);
      box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);
    }
  }
  .tootl-btn {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.4);
    margin-top: 12px;
    &:hover {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
