var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          staticClass: "custom-model",
          attrs: { "mask-closable": false },
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _c("span", { staticClass: "close-btn", on: { click: _vm.close } }),
          _c("div", [
            _c("img", {
              attrs: {
                width: "562px",
                height: "472px",
                src: require("../assets/images/vip-month2.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "btn-group" }, [
              _c(
                "div",
                { staticClass: "tab-btn", on: { click: _vm.confirm } },
                [_vm._v("立即领取")]
              ),
              _c(
                "div",
                { staticClass: "tootl-btn", on: { click: _vm.noShow } },
                [_vm._v("不再提示")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }