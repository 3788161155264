const assign = require("lodash/assign");
const defaultConfig = require("./config.default.js");

const config = assign({}, defaultConfig, {
  proxyHost: "https://t.qianlimu.com",
  // proxyHost: "http://192.168.20.204:7410",
  loginUrl: "https://penelope-test.changjing.com.cn",
  // loginUrl: "http://dev.changjing.com.cn:8081",
  // loginUrl: "http://account.changjing.com.cn",
  appId: "wxc297ecd19a71a006",
  encypt: false
});

module.exports = config;
