var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-wrap", class: _vm.fromClass ? _vm.fromClass : "" },
    [
      _c(
        "div",
        { staticClass: "input-wrap display-flex" },
        [
          _vm.cate === "brand" && _vm.isHome
            ? _c(
                "span",
                { staticClass: "custom-search" },
                [
                  _c(
                    "Poptip",
                    {
                      attrs: {
                        trigger: "click",
                        placement: "top-start",
                        "popper-class": "custom-poptip",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("Category", {
                                  attrs: { isHome: _vm.isHome },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2930089853
                      ),
                    },
                    [
                      _c("span", { staticClass: "search-tool" }, [
                        _c("img", {
                          attrs: {
                            width: "16",
                            height: "16",
                            src: "/images/home/category.png",
                            alt: "",
                          },
                        }),
                        _vm._v(" 品牌分类 "),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("Input", {
            staticClass: "input",
            attrs: { placeholder: _vm.placeholder },
            on: {
              "on-change": _vm.handleSearch,
              "on-enter": _vm.handelSearch,
              "on-focus": _vm.handleSearch,
            },
            model: {
              value: _vm.searchKey,
              callback: function ($$v) {
                _vm.searchKey = $$v
              },
              expression: "searchKey",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchKey,
                expression: "searchKey",
              },
            ],
            staticClass: "clear-btn middle-box cur-pointer",
            attrs: {
              src: require("../assets/images/icon/icon_clear.png"),
              alt: "",
            },
            on: { click: _vm.clearKey },
          }),
          _c("span", { on: { click: _vm.handelSearch } }, [
            _vm.fromClass === "home-wrap"
              ? _c(
                  "span",
                  {
                    staticClass:
                      "search-btn cur-pointer display-flex align-items-center justify-content-center",
                  },
                  [
                    _c("img", {
                      attrs: { src: "/images/home/search.png", alt: "" },
                    }),
                    _vm._v(" 搜索 "),
                  ]
                )
              : _c("span", { staticClass: "search-btn2 cur-pointer" }, [
                  _c("img", {
                    staticClass: "center-middle-box",
                    attrs: {
                      src: require("../assets/images/icon/search.png"),
                      alt: "",
                    },
                  }),
                ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeList,
              expression: "closeList",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowList,
              expression: "isShowList",
            },
          ],
          staticClass: "search-content",
          class: !_vm.isHome ? "other-modules-search-content" : "",
        },
        [
          _vm._l(_vm.searchList, function (item, index) {
            return _c(
              "div",
              {
                key: "s_" + index,
                staticClass: "search-item text-ellipsis cur-pointer",
                class:
                  !_vm.isHome &&
                  (_vm.cate !== "location" || _vm.cate !== "mall")
                    ? "other-modules-item"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.searchRecord(item)
                  },
                },
              },
              [
                !_vm.cate || _vm.cate === "all"
                  ? _c(
                      "span",
                      {
                        staticClass: "item-tag text-c",
                        class: "item-tag" + item.matchingSort,
                      },
                      [_vm._v(_vm._s(_vm.tagObj[item.matchingSort]))]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "item-style",
                    class: _vm.isHome ? "item-style-home" : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "search-item-name justify-content-space-between",
                        class: _vm.isHome ? "display-flex single-item" : "",
                      },
                      [
                        _c("span", {
                          staticClass: "item-name",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.reaultName(item.matchingWord)
                            ),
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "search-item-content",
                        class: "other-modules-" + _vm.cate,
                      },
                      _vm._l(
                        _vm.result(item.matchingSort),
                        function (tag, index) {
                          return _c(
                            "span",
                            { key: index, staticClass: "label" },
                            [
                              _vm.isHome
                                ? _c("span", [_vm._v(_vm._s(tag.label) + "：")])
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "content",
                                  class:
                                    _vm.cate != "location" && _vm.cate != "mall"
                                      ? "content-border"
                                      : "",
                                  attrs: {
                                    title:
                                      _vm.filterEmptyVal(item[tag.key]) || "",
                                  },
                                },
                                [
                                  !_vm.isHome &&
                                  (_vm.cate === "location" ||
                                    _vm.cate === "mall")
                                    ? _c("img", {
                                        staticClass: "icon-point",
                                        attrs: {
                                          src: require("../assets/images/icon/icon_point.png"),
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.filterEmptyVal(item[tag.key]) ||
                                          _vm.filterEmptyVal(item[tag.key2]) ||
                                          "--"
                                      )
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                ),
              ]
            )
          }),
          !_vm.searchList.length
            ? _c(
                "div",
                { staticClass: "no-data" },
                [
                  _c("no-data", {
                    attrs: { img: _vm.noDataImg, text: _vm.noDataText },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isHome && _vm.searchList.length
            ? _c(
                "div",
                {
                  staticClass: "more-btn cur-pointer",
                  on: { click: _vm.handelSearch },
                },
                [
                  _vm._v(" 查看更多 "),
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/icon/icon_right_blue.png"),
                      alt: "",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }