import { Modal } from "view-design";
import { login, handleOk } from "./utils";
import Vue from "vue";
import ModalUpgrade from "@/components/ModalUpgrade.vue";
export const globalVipDialog = (isLogin) => {
  if (isLogin) {
    document.querySelector("body").classList.add("vip-dialog-wrapper");
  } else {
    document.querySelector("body").classList.add("vip-dialog-wrapper");
    document.querySelector("body").classList.add("visitor-dialog-wrapper");
  }
  const LoginContent = `<section class="g-vip-dialog-section">
        <p>（普通版本登录后每日仅可查看 <span>30</span> 个品牌（及其所有门店）&位置&商圈&商场&城市详情）</p>
    </section>`;
  const visitorContent = `<section class="g-visitor-dialog-section">
      <p class="g-visitor-info">登录后每日均可查看 <span>30</span> 个品牌（及其所有门店）、位置、商圈、商场、城市详情</p>
      <p class="g-visitor-tips">（游客仅可查看 <span>10</span> 个品牌&位置&商圈&商场&城市详情）</p>
    </section>`;
  Modal.confirm({
    title: "",
    content: isLogin ? LoginContent : visitorContent,
    width: isLogin ? "910px" : "453px",
    cancelText: "占位",
    okText: isLogin ? "去升级" : "立即登录",
    onOk: () => {
      if (isLogin) {

        // window.open("/vip", "_blank");
        // 打开留资弹窗
        let ModalUpgradeIns = null;
        const ModalUpgradeCon = Vue.extend(ModalUpgrade);
        if (ModalUpgradeIns && ModalUpgradeIns.isOpen) {
          return ModalUpgradeIns;
        }
        let parent = document.body;
        let instance = new ModalUpgradeCon({
          el: document.createElement("div"),
        });
        parent.appendChild(instance.$el);
        instance.isOpen = true;
        ModalUpgradeIns = instance;
        return instance;
      } else {
        login();
      }
      setTimeout(() => {
        document.querySelector("body").classList.remove("vip-dialog-wrapper");
        document
          .querySelector("body")
          .classList.remove("visitor-dialog-wrapper");
      }, 500);
    },
    onCancel: () => {
      setTimeout(() => {
        document.querySelector("body").classList.remove("vip-dialog-wrapper");
        document
          .querySelector("body")
          .classList.remove("visitor-dialog-wrapper");
      }, 500);
    },
  });
};
