var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c("div", { staticClass: "edit-pwd" }, [
        _c("div", { staticClass: "mask" }),
        _c("div", { staticClass: "dialog" }, [
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("img", {
              staticClass: "close-icon",
              attrs: {
                src: require("@/assets/images/icon/close@2x.png"),
                alt: "",
              },
              on: { click: _vm.close },
            }),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "txt1 block1" }, [_vm._v("中国 +86")]),
              _c("div", { staticClass: "input-wrap" }, [
                _c("input", {
                  directives: [
                    { name: "focusblur", rawName: "v-focusblur" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phone,
                      expression: "phone",
                    },
                  ],
                  staticClass: "input-phone",
                  attrs: {
                    type: "text",
                    autocomplete: "off",
                    placeholder: "请输入手机号",
                  },
                  domProps: { value: _vm.phone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.phone = $event.target.value
                    },
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.phone,
                      expression: "phone",
                    },
                  ],
                  staticClass: "close-input-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_clear.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.phone = ""
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "input-wrap" }, [
                _c("input", {
                  directives: [
                    { name: "focusblur", rawName: "v-focusblur" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verCode,
                      expression: "verCode",
                    },
                  ],
                  staticClass: "ver-code",
                  attrs: { type: "text", placeholder: "请输入验证码" },
                  domProps: { value: _vm.verCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.verCode = $event.target.value
                    },
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.verCode,
                      expression: "verCode",
                    },
                  ],
                  staticClass: "close-input-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_clear.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.verCode = ""
                    },
                  },
                }),
              ]),
              _c(
                "span",
                {
                  staticClass: "ver-code-time",
                  class: {
                    "no-hover": _vm.timer,
                    "light-ver-code": _vm.phoneRule() && _vm.timer === 0,
                  },
                  on: { click: _vm.getSmsCodeHandler },
                },
                [_vm._v(_vm._s(_vm.hintTxt))]
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "input-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.stateLookPwd,
                      expression: "!stateLookPwd",
                    },
                    { name: "focusblur", rawName: "v-focusblur" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newPwd,
                      expression: "newPwd",
                    },
                  ],
                  staticClass: "fw-input",
                  attrs: {
                    type: "password",
                    autocomplete: "new-password",
                    placeholder: "6 - 16 位密码，区分大小写",
                  },
                  domProps: { value: _vm.newPwd },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newPwd = $event.target.value
                    },
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newPwd,
                      expression: "newPwd",
                    },
                    { name: "focusblur", rawName: "v-focusblur" },
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stateLookPwd,
                      expression: "stateLookPwd",
                    },
                  ],
                  staticClass: "fw-input",
                  attrs: {
                    type: "text",
                    autocomplete: "new-password",
                    placeholder: "6 - 16 位密码，区分大小写",
                  },
                  domProps: { value: _vm.newPwd },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newPwd = $event.target.value
                    },
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.stateLookPwd,
                      expression: "!stateLookPwd",
                    },
                  ],
                  staticClass: "pwd-input-icon",
                  attrs: {
                    src: require("@/assets/images/icon/eye@2x.png"),
                    alt: "",
                  },
                  on: { click: _vm.lookPwdHandler },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stateLookPwd,
                      expression: "stateLookPwd",
                    },
                  ],
                  staticClass: "pwd-input-icon-hover",
                  attrs: {
                    src: require("@/assets/images/icon/eye_hover@2x.png"),
                    alt: "",
                  },
                  on: { click: _vm.lookPwdHandler },
                }),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "input-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.stateNewLookPwd,
                      expression: "!stateNewLookPwd",
                    },
                    { name: "focusblur", rawName: "v-focusblur" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rePwd,
                      expression: "rePwd",
                    },
                  ],
                  staticClass: "fw-input",
                  attrs: {
                    type: "password",
                    autocomplete: "new-password",
                    placeholder: "确认密码",
                  },
                  domProps: { value: _vm.rePwd },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.rePwd = $event.target.value
                    },
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stateNewLookPwd,
                      expression: "stateNewLookPwd",
                    },
                    { name: "focusblur", rawName: "v-focusblur" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rePwd,
                      expression: "rePwd",
                    },
                  ],
                  staticClass: "fw-input",
                  attrs: {
                    type: "text",
                    autocomplete: "new-password",
                    placeholder: "确认密码",
                  },
                  domProps: { value: _vm.rePwd },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.rePwd = $event.target.value
                    },
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.stateNewLookPwd,
                      expression: "!stateNewLookPwd",
                    },
                  ],
                  staticClass: "pwd-input-icon",
                  attrs: {
                    src: require("@/assets/images/icon/eye@2x.png"),
                    alt: "",
                  },
                  on: { click: _vm.lookNewPwdHandler },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stateNewLookPwd,
                      expression: "stateNewLookPwd",
                    },
                  ],
                  staticClass: "pwd-input-icon-hover",
                  attrs: {
                    src: require("@/assets/images/icon/eye_hover@2x.png"),
                    alt: "",
                  },
                  on: { click: _vm.lookNewPwdHandler },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "btn", on: { click: _vm.btnHandler } }, [
              _c("span", { staticClass: "main-btn" }, [_vm._v("确定")]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }