const assign = require("lodash/assign");
const defaultConfig = require("./config.default.js");

const config = assign({}, defaultConfig, {
  proxyHost: "/",
  loginUrl: "https://account.changjing.com.cn/",
  appId: "wxd357f8ec99f2a8fc",
  encypt: true,
});

module.exports = config;
