<template>
  <div class="map-list-wrap" :class="{ 'map-list-wrap-fold': !isExpand }">
    <div class="map-list-left-panel shadow-sm">
      <div class="left-panel-expand-wrap">
        <div class="left-panel-wrap">
          <slot name="leftPanel"></slot>
        </div>
      </div>
      <div class="expand-btn" @click="isExpand = !isExpand"></div>
    </div>
    <div class="map-list-map-panel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpand: true,
    };
  },
  watch: {
    isExpand(newval) {
      setTimeout(() => {
        this.$emit("changed", newval);
      }, 190);
    },
  },
};
</script>

<style></style>
