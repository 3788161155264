import Vue from "vue";
import ModalConfirm from "./ModalConfirm";
const instance = new Vue({
  render: (h) => h(ModalConfirm, { ref: "modalconfirm" }),
  methods: {
    open(obj) {
      this.$refs.modalconfirm.open(obj);
    },
    close() {
      this.$refs.modalconfirm.close();
    },
  },
});

const component = instance.$mount();
document.body.appendChild(component.$el);
const install = function (Vue, options) {
  if (options.global) {
    options.global.confirm = instance.open;
    options.global.closeConfirm = instance.close;
  } else {
    Vue.prototype.$confirm = instance.open;
    Vue.prototype.$closeConfirm = instance.close;
  }
};
export default {
  install,
};
