var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avatar" },
    [
      _c("Poptip", { attrs: { trigger: "hover", placement: "bottom-end" } }, [
        !_vm.avatarImg
          ? _c("div", {
              staticClass: "login-user-img-default",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.gotoPage()
                },
                mouseenter: _vm.openMenu,
                mouseleave: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeMenu.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm.avatarImg
          ? _c("img", {
              staticClass: "login-user-img",
              attrs: { src: _vm.avatarImg, alt: "用户头像" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.gotoPage()
                },
              },
            })
          : _vm._e(),
        _c("div", { attrs: { slot: "content" }, slot: "content" }, [
          _c(
            "div",
            {
              staticClass: "user-little-menu",
              class: { "user-little-menu-mode2": _vm.mode === 2 },
            },
            [
              _c("div", { staticClass: "user-msg" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.avatarImg || _vm.defaultUserImg,
                    alt: "用户头像",
                  },
                }),
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "user-name" }, [
                      _vm._v(_vm._s(_vm.userInfo.phone)),
                    ]),
                    [
                      _c("div", { staticClass: "user-normal" }, [
                        _vm._v(
                          " " + _vm._s(_vm.userInfo.userMember.desc) + " "
                        ),
                      ]),
                    ],
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                { staticClass: "menu-list" },
                _vm._l(_vm.menuList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.name + index,
                      staticClass: "menu-item",
                      class: {
                        "active-menu-item":
                          item.name === _vm.routeMsg.page &&
                          _vm.routeMsg.name === "user",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.gotoPage(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "menu-img",
                        attrs: { src: item.icon, alt: "" },
                      }),
                      _c("img", {
                        staticClass: "hover-menu-img",
                        attrs: { src: item.hoverIcon, alt: "" },
                      }),
                      _c("span", [_vm._v(_vm._s(item.title))]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }