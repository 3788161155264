<template>
  <div class="search-wrap" :class="fromClass ? fromClass : ''">
<!--    <div-->
<!--      class="input-wrap display-flex"-->
<!--      :class="fromClass === 'home-wrap' ? 'center-box' : ''"-->
<!--    >-->
    <div
        class="input-wrap display-flex"
    >
      <span class="custom-search" v-if="cate === 'brand' && isHome">
        <Poptip trigger="click" placement="top-start" popper-class="custom-poptip">
          <span class="search-tool">
            <img width="16" height="16" src="/images/home/category.png" alt="">
            品牌分类
          </span>
          <template #content>
            <Category :isHome="isHome"></Category>
          </template>
        </Poptip>
      </span>
      <Input
        class="input"
        v-model="searchKey"
        :placeholder="placeholder"
        @on-change="handleSearch"
        @on-enter="handelSearch"
        @on-focus="handleSearch"
      ></Input>
      <img
        v-show="searchKey"
        @click="clearKey"
        class="clear-btn middle-box cur-pointer"
        src="../assets/images/icon/icon_clear.png"
        alt=""
      />
      <span @click="handelSearch">
        <span
          v-if="fromClass === 'home-wrap'"
          class="search-btn cur-pointer display-flex align-items-center justify-content-center"
        >
          <img src="/images/home/search.png" alt="" />
          搜索
        </span>
        <span v-else class="search-btn2 cur-pointer">
          <img
            class="center-middle-box"
            src="../assets/images/icon/search.png"
            alt=""
          />
        </span>
      </span>
    </div>
    <div
      v-click-outside="closeList"
      v-show="isShowList"
      :class="!isHome ? 'other-modules-search-content' : ''"
      class="search-content"
    >
      <div
        class="search-item text-ellipsis cur-pointer"
        :class="
          !isHome && (cate !== 'location' || cate !== 'mall')
            ? 'other-modules-item'
            : ''
        "
        v-for="(item, index) in searchList"
        :key="'s_' + index"
        @click="searchRecord(item)"
      >
        <span
          v-if="!cate || cate === 'all'"
          class="item-tag text-c"
          :class="'item-tag' + item.matchingSort"
          >{{ tagObj[item.matchingSort] }}</span
        >
        <div class="item-style" :class="isHome ? 'item-style-home' : ''">
          <div
            class="search-item-name justify-content-space-between"
            :class="isHome ? 'display-flex single-item' : ''"
          >
            <span
              class="item-name"
              v-html="reaultName(item.matchingWord)"
            ></span>
          </div>
          <div class="search-item-content" :class="'other-modules-' + cate">
            <span
              class="label"
              v-for="(tag, index) in result(item.matchingSort)"
              :key="index"
            >
              <span v-if="isHome">{{ tag.label }}：</span>
              <span
                class="content"
                :class="
                  cate != 'location' && cate != 'mall' ? 'content-border' : ''
                "
                :title="filterEmptyVal(item[tag.key]) || ''"
              >
                <img
                  class="icon-point"
                  v-if="!isHome && (cate === 'location' || cate === 'mall')"
                  src="../assets/images/icon/icon_point.png"
                  alt=""
                />
                {{ filterEmptyVal(item[tag.key]) ||filterEmptyVal(item[tag.key2]) || "--" }}</span
              >
            </span>
          </div>
        </div>
      </div>
      <div v-if="!searchList.length" class="no-data">
        <no-data :img="noDataImg" :text="noDataText"></no-data>
      </div>
      <div
        v-if="isHome && searchList.length"
        @click="handelSearch"
        class="more-btn cur-pointer"
      >
        查看更多
        <img src="../assets/images/icon/icon_right_blue.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  SearchMatching,
  SearchRecord,
  GetDetailBrowsingRecord,
} from "../service";
import { openNewWindow } from "../assets/javascript/utils";
import Category from "./Category.vue";

export default {
  props: [
    "cate",
    "fromClass",
    "placeholder",
    "isHome",
    "searchWords",
    "cityCode",
  ],
  components: {
    Category
  },
  data() {
    return {
      searchKey: "",
      searchList: [],
      cateObj: {
        all: 0,
        location: 1,
        brand: 2,
        mall: 3,
        business: 4,
        city: 5,
      },
      tagObj: {
        1: "位置",
        2: "品牌",
        3: "商场",
        4: "商圈",
        5: "城市",
      },
      homeSearchConent: {
        1: [
          {
            label: "地址",
            key: "addr",
          },
          {
            label: "类别",
            key: "middleType",
          },
        ],
        3: [
          {
            label: "商场位置",
            key: "addr",
          },
          {
            label: "开业时间",
            key: "openTime",
          },
        ],
        5: [
          {
            label: "城市级别",
            key: "cityLevel",
          },
          {
            label: "城市等级",
            key: "cityLineLevel",
          },
          {
            label: "常住人口",
            key: "residentPopulationAlias",
          },
        ],
        2: [
          {
            label: "类别",
            key: "smallType" ,
            key2:"middleType"
          },
          {
            label: "所属公司",
            key: "company",
          },
          {
            label: "成立时间",
            key: "openTime",
          },
        ],
        4: [
          {
            label: "商圈级别",
            key: "level",
          },
          {
            label: "商圈等级",
            key: "type",
          },
          {
            label: "商圈商业项目",
            key: "childrenNumber",
          },
        ],
      },
      searchConent: {
        1: [
          {
            label: "地址",
            key: "addr",
          },
        ],
        3: [
          {
            label: "商场位置",
            key: "addr",
          },
        ],
        5: [
          {
            label: "城市级别",
            key: "cityLevel",
          },
        ],
        2: [
          {
            label: "类别",
            key: "smallType",
          },
        ],
        4: [
          {
            label: "商圈级别",
            key: "level",
          },
        ],
      },
      isShowList: false,
      noDataImg: "/images/no-data.png",
      noDataText: "暂无搜索数据",
      show: false,
      pageJump: {
        1: {
          page: "/location",
          detail: "/location",
        },
        2: {
          page: "/brand",
          detail: "/brandDetail",
        },
        3: {
          page: "/mall",
          detail: "/mallDetail",
        },
        4: {
          page: "/business",
          detail: "/businessDetail",
        },
        5: {
          page: "/city",
          detail: "/cityDetail",
        },
      },
      mustGetSearchList: false, //当tab为 位置 时，必须要获取具体位置才能跳转页面 获取具体位置就必须请求接口 获取搜索列表，将搜索列表的第一项传给 查位置页面
      isClickSearchBtn: false, //当前是否点击search按钮
    };
  },
  computed: {
    result() {
      return function (val) {
        if (this.isHome) {
          return this.homeSearchConent[val];
        } else {
          return this.searchConent[val];
        }
      };
    },
    reaultName() {
      return function (val) {
        if (!this.searchKey) return val;
        let replace = new RegExp(this.searchKey, "ig");
        let str = `<span style='color:#3519FB'>${this.searchKey}</span>`;
        return val.replace(replace, str);
      };
    },
  },
  watch: {
    searchWords(val) {
      if (val) {
        this.searchKey = val;
      }
    },
    cate: {
      handler: function (val) {
        this.isClickSearchBtn = false;
        if (val === "location") {
          this.mustGetSearchList = true;
        } else {
          this.mustGetSearchList = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSearch(e) {
      if (!this.searchKey) {
        this.isShowList = false;
        this.searchList = [];
        return;
      }
      // this.isShowList = true;
      let cName = e.target.value;
      let that = this;
      setTimeout(function () {
        if (that.searchKey == cName) {
          that.getSearchList();
        }
      }, 50);
    },
    async getSearchList() {
      let params = {
        matchingSort: this.cate ? this.cateObj[this.cate] : this.cateObj["all"],
        word: this.searchKey,
        cityCode: "",
      };
      let res = await SearchMatching(params);
      this.isShowList = true;
      if (res && res.code === 200) {
        this.searchList = res.data.items || [];
      }
      if (this.mustGetSearchList && this.isClickSearchBtn) {
        this.mustGetSearchList = false;
        this.searchRecord(this.searchList[0]);
      }
    },
    clearKey() {
      this.searchKey = "";
      this.searchList = [];
    },
    closeList() {
      this.isShowList = false;
    },
    async searchRecord(item) {
      let params = {
        sort: this.cate ? this.cateObj[this.cate] : this.cateObj["all"],
        searchInfo: this.searchKey,
        realInfo: item.matchingWord,
      };
      await SearchRecord(params);
      this.clickDetail(item);
    },
    async clickDetail(item) {
      let page = this.pageJump[item.matchingSort].detail;
      const res = await GetDetailBrowsingRecord({
        module: page == "/location" ? "locationDetail" : page.split("/")[1],
        detailID: item.code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow(page, {
          id: item.code,
          key: item.matchingWord,
        });
      }
    },
    handelSearch() {
      this.isClickSearchBtn = true;
      //判断是否需要获取列表，并且已经获取列表数据
      if (this.searchKey) {
        if (this.mustGetSearchList && this.searchList.length > 0) {
          this.mustGetSearchList = false;
          this.searchRecord(this.searchList[0]);
          this.isClickSearchBtn = false;
          return;
        } else if (this.mustGetSearchList) {
          return;
        }
      }
      if (this.cate && this.cate !== "all") {
        if (this.cate === "location") {
          let item = this.searchList[0];
          this.clickDetail(item);
          return;
        }
        this.$emit("search", this.searchKey, this.searchList);
        return;
      }
      // if (this.searchList.length) {
      //   this.$emit("search", this.searchList[0]);
      //   return;
      // }
      this.$emit("searchAll", this.searchKey);
      this.isClickSearchBtn = false;
    },
    filterEmptyVal(str) {
      if (!str || str === "[]") {
        return "";
      }
      return str;
    },
  },

  mounted() {
    this.searchKey = this.searchWords;
  },
};
</script>

<style lang="less">
@import "../assets/stylesheets/config.less";
.search-wrap {
  width: 465px;
  position: relative;
  .input-wrap {
    width: 100%;
    position: relative;
    border-radius: 2px;
    border: 1px solid #d0d0d0;
    .custom-search {
      position: absolute;
      left: -110px;
      top: 0;
    }
    .search-tool {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 48px;
      font-weight: 500;
      color: #3519FB;
      font-size: 14px;
      background: url("/images/home/search-bg.png") no-repeat;
      background-size: 100%;
      cursor: pointer;
      img {
        margin-right: 5px;
        vertical-align: middle;
      }
      &:hover {
        color: #5557FF;
      }
    }
    .input {
      width: 100%;
      .ivu-input {
        border: none;
        padding: 4px 14px;
      }
    }
    .clear-btn {
      position: absolute;
      width: 14px;
      height: 14px;
      right: 50px;
    }
    .search-btn2 {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      width: 42px;
      height: 100%;
      border-left: 1px solid #d0d0d0;
      img {
        width: 17px;
        height: 17px;
      }
    }
  }
}
.home-wrap {
  margin: auto;
  position: relative;
  width: 790px;
  .input-wrap {
    border: none;
    border-radius: 4px;
    position: relative;
    .clear-btn {
      position: absolute;
      right: 102px;
      width: 20px;
      height: 20px;
    }
    width: 790px;
    height: 48px;
    line-height: 48px;
    .input {
      height: 100%;
      width: 700px;
      input {
        height: 100%;
        border-radius: 0;
        border-radius: 4px 0px 0px 4px;
      }
    }
    .search-btn {
      width: 90px;
      font-weight: 600;
      color: #ffffff;
      background: #f99000;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #f98300;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }
}
.search-content {
  position: absolute;
  z-index: 9900;
  background: #fff;
  overflow: auto;
  height: auto;
  width: 100%;
  max-height: 522px;
  margin-top: 4px;

  box-shadow: 0px 6px 18px -3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  .search-item {
    padding: 0 16px;
    padding-top: 14px;
    padding-bottom: 12px;
    display: flex;
    align-items: baseline;
    .item-style {
      display: flex;
      align-items: center;
      max-width: 100%;
    }
    .item-style-home {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .item-style-home {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &:hover {
      background: rgba(53, 25, 251, 0.04);
    }
    .item-tag {
      background: rgba(53, 25, 251, 0.1);
      width: 50px;
      height: 26px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 26px;
      margin-right: 8px;
      &.item-tag1 {
        color: #ff4747;
        background: rgba(255, 71, 71, 0.1);
      }
      &.item-tag2 {
        color: #3519fb;
        background: rgba(53, 25, 251, 0.1);
      }
      &.item-tag3 {
        color: #52c41b;
        background: rgba(82, 196, 27, 0.1);
      }
      &.item-tag4 {
        color: #f99000;
        background: rgba(249, 144, 0, 0.1);
      }
      &.item-tag5 {
        color: #bd59f9;
        background: rgba(189, 89, 249, 0.1);
      }
    }
    .search-item-name {
      .item-name {
        display: inline-block;
        height: 100%;
        font-size: 16px;
        line-height: 30px;

        font-weight: 600;
        color: #333333;
      }
    }
    .single-item {
      width: 100%;
    }
    .search-item-content {
      color: rgba(0, 0, 0, 0.4);
      position: relative;
      .label {
        margin-right: 24px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.4);
        .content {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  .no-data {
    height: 400px;
    text-align: center;
  }
  .more-btn {
    text-align: center;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    color: #3519fb;
    img {
      vertical-align: middle;
      width: 14px;
      height: 14px;
      margin-top: -3px;
    }
  }
}
.other-modules-search-content {
  max-height: 360px;
  .search-item {
    padding: 0 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    .search-item-content {
      display: flex;
      align-items: center;
      overflow: hidden;
      /*padding-right: 24px;*/
      flex-shrink: 1;
      max-width: 100%;
      .label {
        position: relative;
        max-width: 100%;
        .content {
          color: #848295;
          display: block;
          height: 18px;
          font-size: 12px;
          border-radius: 4px;
          /*border: 1px solid #b1afc0;*/
          margin-left: 10px;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 6px;
          line-height: 16px;
        }
        .content-border {
          border: 1px solid #b1afc0;
        }
      }
    }
  }
  .other-modules-item {
    display: flex;
    justify-content: flex-start;
    align-content: center;
  }
  .other-modules-location,
  .other-modules-mall {
    .icon-point {
      width: 11px;
      height: 14px;
      margin: 1px 3px 0 0;
      float: left;
    }
  }
}
.custom-poptip {
  z-index: 9999;
  .ivu-poptip-body {
    padding: 0;
  }
  .ivu-poptip-body-content {
    border-radius: 4px;
  }
}
</style>
