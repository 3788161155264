import Vue from "vue";
import { mapState } from "vuex";
const config = require("@/config/config." + process.env.NODE_ENV + ".js");
import router from "@/router";

//弹框点击了关闭
export const handleOk = function () {
  console.log(mapState, "state");
  state.state.isUpgrade = true;
};

// 登录url
export const login = function () {
  let loginUrl = config.loginUrl;
  let redirect = redirectBack();
  let url = `${loginUrl}?redirectUrl=${redirect}&proName=qianlimu&operationType=login`;
  window.location.href = url;
};

export const isLogin = function () {
  const TOKEN = localStorage.getItem("token");
  const USER_INFO = localStorage.getItem("userInfo");
  return TOKEN && USER_INFO ? true : false;
};

export const redirectBack = function () {
  let domain = window.location.href.split("/");
  let url = domain[0] + "//" + domain[2] + "/back";
  return url;
};
// 克隆对象
export const cloneObj = function (obj) {
  var o;
  if (typeof obj === "object") {
    if (obj === null) {
      o = null;
    } else {
      if (obj instanceof Array) {
        o = [];
        for (var i = 0, len = obj.length; i < len; i++) {
          o.push(cloneObj(obj[i]));
        }
      } else {
        o = {};
        for (var j in obj) {
          o[j] = cloneObj(obj[j]);
        }
      }
    }
  } else {
    o = obj;
  }
  return o;
};
export const deepAssign = (...param) => {
  let result = Object.assign({}, ...param);
  for (let item of param) {
    for (let [idx, val] of Object.entries(item)) {
      if (Array.isArray(val)) {
        result[idx] = val;
      } else if (typeof val === "object") {
        result[idx] = deepAssign(result[idx], val);
      }
    }
  }
  return result;
};
/**
 * 格式化通用接口请求回来的列表
 * @param { object } data 服务端返回参数
 * @param { object } _labelKeys 替换key为自己的值
 */
export const formatFieldsList = (data, _labelKeys) => {
  let labelKeys = _labelKeys || {};
  let fields = data.fields || [];
  let items = data.items || [];
  let list = [];
  for (let i = 0, n = items.length; i < n; i++) {
    let item = items[i];
    if (!item) {
      continue;
    }
    let newItem = {};
    for (let j = 0, m = fields.length; j < m; j++) {
      let field = fields[j];
      if (labelKeys[field]) {
        field = labelKeys[field];
      }
      newItem[field] = item[j];
    }
    list.push(newItem);
  }
  return list;
};
// 只有一个选项
export const formatFieldsItem = (data, labelKeys) => {
  let list = formatFieldsList(data, labelKeys);
  return list[0];
};
// 位置相关的请求处理，与其他有区别
export const formatFieldsList2 = (data) => {
  let fields = data.fields || [];
  let items = data.items || [];
  let list = [];
  if (!items[0]) {
    return list;
  }
  let n = items[0].length;
  for (let i = 0; i < n; i++) {
    let newItem = {};
    for (let j = 0, m = fields.length; j < m; j++) {
      let field = fields[j];
      if (!items[j]) {
        continue;
      }
      newItem[field] = items[j][i];
    }
    list.push(newItem);
  }
  return list;
};
export const formatList = (list, labelKeys) => {
  for (let i = 0, n = list.length; i < n; i++) {
    let item = list[i];
    for (let key in labelKeys) {
      let field = labelKeys[key];
      item[field] = item[key];
    }
    list[i] = item;
  }
  return list;
};

/**
 * 获取变量的类型
 * @param value
 * @returns {String}
 */
export function getType(value) {
  return {}.toString.call(value).toLowerCase().slice(8, -1);
}

/**
 * 千分位
 * @param num
 * @returns {String}
 */
export const numFormat = (_num) => {
  if (!_num && _num !== 0) return "";
  let num = Number(_num);
  if (isNaN(num)) {
    return _num;
  }
  if (num.toString().indexOf(".") !== -1) {
    return num.toLocaleString();
  } else {
    return num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  }
};

/**
 * 格式化金额
 * @param number {Number} 要格式化的数字
 * @param unit {Number} 初始单位
 * @returns {String}
 */
export function numberFormat(money, unit = 0, isPlus = false) {
  if (money === null || isNaN(money)) {
    return;
  }
  const unitList = isPlus
    ? [" ", "万", "亿", "万亿"]
    : [" ", " 万+", " 亿+", " 万亿+"];
  const toFixed2 = (number) =>
    ~~number !== number ? ~~number : number.toString();
  if (Math.abs(money) >= 1e12 && unit <= 0) {
    return toFixed2(money / 1e12) + unitList[unit + 3];
  } else if (Math.abs(money) >= 1e8 && unit <= 1) {
    return toFixed2(money / 1e8) + unitList[unit + 2];
  } else if (Math.abs(money) >= 1e4 && unit <= 2) {
    return toFixed2(money / 1e4) + unitList[unit + 1];
  } else if (unit <= 3) {
    return toFixed2(money) + unitList[unit];
  } else {
    return toFixed2(money);
  }
}

/**
 * 格式化时间
 * @param date {Date|Number|String} 要格式化的时间，不传则默认取当前时间
 * @param format {String} 要生成的格式，其中以下字符将被替换成对应的时间，剩下的字符保持不变
 *   Y 年 四位数字
 *   y 年 两位数字
 *   m 月 有前导零
 *   n 月 无前导零
 *   d 日 有前导零
 *   j 日 无前导零
 *   H 时 有前导零
 *   G 时 无前导零
 *   i 分 有前导零
 *   s 秒 有前导零
 *   u 毫秒
 * @returns {String}
 */
export function timeFormat(date = new Date(), format = "Y-m-d") {
  switch (getType(date)) {
    case "date":
      break;
    case "number":
      date = new Date(date);
      break;
    case "string":
      date = new Date(date);
      break;
    default:
      return "";
  }
  const addZero = (value) => value.toString().padStart(2, "0");
  const time = {
    Y: date.getFullYear(),
    y: addZero(date.getFullYear() % 100),
    m: addZero(date.getMonth() + 1),
    n: date.getMonth() + 1,
    d: addZero(date.getDate()),
    j: date.getDate(),
    H: addZero(date.getHours()),
    G: date.getHours(),
    i: addZero(date.getMinutes()),
    s: addZero(date.getSeconds()),
    u: date.getMilliseconds(),
  };
  return format
    .split("")
    .map((value) => {
      return value in time ? time[value] : value;
    })
    .join("");
}

// 打开新页面
export function openNewWindow(path, queryData) {
  if (!path || !queryData) return;
  const { href } = router.resolve({
    path,
    query: queryData,
  });
  window.open(href, "_blank");
}
//HEX十六进制颜色值转换为RGB(A)颜色值
export const hexToRgb = (val, opacity = 1) => {
  if (!val) return "rgba(255, 255, 255, 0)";
  var a, b, c;
  if (/^#/g.test(val)) {
    a = val.slice(1, 3);
    b = val.slice(3, 5);
    c = val.slice(5, 7);
    let result =
      "rgba(" +
      parseInt(a, 16) +
      "," +
      parseInt(b, 16) +
      "," +
      parseInt(c, 16) +
      "," +
      opacity +
      ")";
    return result;
  } else {
    return "";
  }
};
// 重绘png
export const handlePng = (url, opts, callback) => {
  const myImage = new Image();
  myImage.src = url;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  let rgb = hexToRgb(opts.color);
  let r = rgb.split("(")[1].split(")")[0];
  myImage.onload = () => {
    canvas.width = myImage.width || 20;
    canvas.height = myImage.height || 20;
    ctx.drawImage(myImage, 0, 0);
    let imageD = ctx.getImageData(0, 0, myImage.width, myImage.height);
    let pdata = imageD.data;
    let colorArr = r.split(",");
    for (let j = 0; j < pdata.length; j += 4) {
      pdata[j] = colorArr[0];
      pdata[j + 1] = colorArr[1];
      pdata[j + 2] = colorArr[2];
    }
    ctx.putImageData(imageD, 0, 0);
    if (callback) {
      callback(canvas);
    }
  };
};
export const handlePngAsync = (url, opts) => {
  return new Promise((resolve) => {
    handlePng(url, opts, (canvas) => {
      let base64 = canvas.toDataURL();
      resolve(base64);
    });
  });
};

//  校验密码
export const checkPass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
//校验手机号
export const phoneReg = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
//邮箱验证
export const emailReg =
  /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;

//动态加载js文件
export const loadScript = (url) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");

    script.onload = () => resolve();

    script.onerror = () => reject(new Error(`Load script from ${url} failed`));

    script.src = url;
    const head = document.head || document.getElementsByTagName("head")[0];
    (document.body || head).appendChild(script);
  });
};

/**
 * 获取url参数
 * @param {string} url
 * @returns {Object}
 */
export const getQueryObject = (currUrl) => {
  var url = "";
  var hash = "";
  if (currUrl) {
    url = currUrl;
    hash = currUrl;
  } else {
    url = location.search; //获取url中"?"符后的字串
    hash = location.hash;
  }
  url = decodeURIComponent(url);
  hash = decodeURIComponent(hash);
  var strsHash = "";
  var strs = "";
  var theRequestHash = new Object();
  if (hash.indexOf("?") != -1) {
    var strHash = hash.substring(hash.indexOf("?") + 1);
    strsHash = strHash.split("&");
    for (var i = 0; i < strsHash.length; i++) {
      let strArr = strsHash[i].split("=");
      if (strArr.length > 1) {
        theRequestHash[strArr[0]] = decodeURIComponent(strArr[1].split("?")[0]);
      }
    }
  }
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substring(1);
    strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      let strArr = strs[i].split("=");
      if (strArr.length > 1) {
        theRequest[strArr[0]] = decodeURIComponent(strArr[1].split("?")[0]);
      }
    }
  }
  return Object.assign(theRequest, theRequestHash);
};

export function delUrlQueryByKey(key, url) {
  url = url == null ? window.location.href : url;
  url = decodeURIComponent(url);
  const regexp = new RegExp("[?|&]" + key + "=([^&#;]+)?");
  history.replaceState({}, "", url.replace(regexp, ""));
}
/**
 * 计算字符串长度
 * @param { String } str 字符串
 * @return { Number } 实际长度
 */
export const getCharCodeLength = function (str) {
  /// <summary>获得字符串实际长度，中文2，英文1</summary>
  /// <param name="str">要获得长度的字符串</param>
  let realLength = 0;

  let len = str.length;

  let charCode = -1;
  for (var i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) {
      // 大写字母
      if (charCode >= 65 && charCode <= 90) {
        realLength += 2
      }
      realLength += 1
    } else realLength += 2;
  }
  return realLength;
};

export const handleInfoList = function (_info, infoArr) {
  if (!_info.fields || !_info.fields.length || !_info.items) {
    return {
      items: [],
      fields: [],
      units: [],
    };
  }
  let fields = _info.fields || [];
  let items = _info.items[0] || [];
  // infoArr 默认值
  infoArr = infoArr || [0, fields.length];
  let info = {
    fields: fields.slice(infoArr[0], infoArr[1] + 1) || [],
    items: items.slice(infoArr[0], infoArr[1] + 1) || [],
    units: [],
  };
  return info;
};

export const handleTableList = function (items) {
  let list = [];
  if (!items || !items.length) return list;
  for (let i = 0, n = items.length; i < n; i++) {
    let item = items[i];
    let data = {
      index: i + 1,
      key1: item[0],
      key2: item[1],
    };
    list.push(data);
  }
  return list;
};

export const handlePieList = function (list) {
  if (!list) return;
  let result = [];
  for (let i = 0, l = list.length; i < l; i++) {
    let obj = list[i];
    result.push({
      name: obj[0],
      value: obj[1],
    });
  }
  return result;
};

// 检查是否是手机端
export const checkMobile = function() {
  let isMobile = true;
  let userAgent = window.navigator.userAgent;
  if (
    userAgent.indexOf("iPad") == -1 &&
    userAgent.indexOf("iPhone") == -1 &&
    userAgent.indexOf("Android") == -1
  ) {
    isMobile = false;
  }
  return isMobile;
};

// 手机端获取不同分辨率的图片
export const getDprImg = (url, num) => {
  if (!num) {
    num = 2;
  }
  let dpr = Vue.prototype.$global.dpr;
  if (dpr === 2 || num === 2) {
    url = url.replace(".png", "@2x.png");
  } else if (dpr === 3) {
    url = url.replace(".png", "@3x.png");
  }
  return url;
};
