var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "no-data-wrap" }, [
    _c("div", { staticClass: "no-data" }, [
      _c("img", { attrs: { src: _vm.img, alt: "" } }),
      _c("div", {}, [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }