var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c("div", { staticClass: "edit-phone" }, [
        _c("div", { staticClass: "mask" }),
        _c("div", { staticClass: "dialog" }, [
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("img", {
              staticClass: "close-icon",
              attrs: {
                src: require("@/assets/images/icon/close@2x.png"),
                alt: "",
              },
              on: { click: _vm.close },
            }),
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statusEdit === 1 && _vm.mode === "edit",
                    expression: "statusEdit === 1 && mode === 'edit'",
                  },
                ],
                staticClass: "item",
              },
              [
                _c("span", { staticClass: "txt1" }, [
                  _vm._v("现有手机号：" + _vm._s(_vm.phone)),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      (_vm.statusEdit !== 1 && _vm.mode === "edit") ||
                      _vm.mode === "bind",
                    expression:
                      "(statusEdit !== 1 && mode === 'edit') || mode === 'bind'",
                  },
                ],
                staticClass: "item",
              },
              [
                _c("span", { staticClass: "txt1 block1" }, [
                  _vm._v("中国 +86"),
                ]),
                _c("div", { staticClass: "input-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPhone,
                        expression: "newPhone",
                      },
                    ],
                    staticClass: "input-phone",
                    attrs: { type: "text" },
                    domProps: { value: _vm.newPhone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.newPhone = $event.target.value
                      },
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.newPhone,
                        expression: "newPhone",
                      },
                    ],
                    staticClass: "close-input-icon",
                    attrs: {
                      src: require("@/assets/images/icon/icon_clear.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.newPhone = ""
                      },
                    },
                  }),
                ]),
              ]
            ),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "input-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verCode,
                      expression: "verCode",
                    },
                  ],
                  staticClass: "ver-code",
                  attrs: { type: "text", placeholder: "请输入验证码" },
                  domProps: { value: _vm.verCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.verCode = $event.target.value
                    },
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.verCode,
                      expression: "verCode",
                    },
                  ],
                  staticClass: "close-input-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_clear.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.verCode = ""
                    },
                  },
                }),
              ]),
              _c(
                "span",
                {
                  staticClass: "ver-code-time",
                  class: { "no-hover": _vm.timer },
                  on: { click: _vm.getSmsCodeHandler },
                },
                [_vm._v(_vm._s(_vm.hintTxt))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "btn", on: { click: _vm.btnHandler } }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.statusEdit === 1 && _vm.mode === "edit",
                      expression: "statusEdit === 1 && mode === 'edit'",
                    },
                  ],
                  staticClass: "main-btn",
                },
                [_vm._v("下一步")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        (_vm.statusEdit !== 1 && _vm.mode === "edit") ||
                        _vm.mode === "bind",
                      expression:
                        "(statusEdit !== 1 && mode === 'edit') || mode === 'bind'",
                    },
                  ],
                  staticClass: "main-btn",
                },
                [_vm._v("确定")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }