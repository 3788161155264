<template>
  <div class="avatar">
    <Poptip trigger="hover" placement="bottom-end">
      <div
      class="login-user-img-default"
      v-if="!avatarImg"
      @click.stop="gotoPage()"
      @mouseenter="openMenu"
      @mouseleave.stop="closeMenu"
    ></div>
        <img
          v-if="avatarImg"
          :src="avatarImg"
          alt="用户头像"
          class="login-user-img"
          @click.stop="gotoPage()"
        />
        <div slot="content">
          <div
            class="user-little-menu"
            :class="{ 'user-little-menu-mode2': mode === 2 }"

          >
            <div class="user-msg">
              <img
                :src="avatarImg || defaultUserImg"
                alt="用户头像"
                class="user-avatar"
              />
              <div>
                <span class="user-name">{{ userInfo.phone }}</span>
                <template>
                  <div class="user-normal">
                      {{ userInfo.userMember.desc }}
                  </div>
                </template>
                <!-- <template v-if="isNormal">
                  <div class="user-normal">
                    <img
                      :src="require('@/assets/images/icon/normal_icon.png')"
                      width="60"
                      height="19"
                    />
                  </div>
                </template>
                <template v-if="isVip">
                  <div class="user-vip">
                    <img
                      :src="require('@/assets/images/icon/user_vip_icon.png')"
                      width="73"
                      height="19"
                    />
                  </div>
                </template>
                <template v-if="isSvip">
                  <div class="user-svip">
                    <img
                      :src="require('@/assets/images/icon/user_svip_icon.png')"
                      width="80"
                      height="19"
                    />
                  </div>
                </template> -->
              </div>
            </div>
            <div class="menu-list">
              <div
                class="menu-item"
                :class="{
                  'active-menu-item':
                    item.name === routeMsg.page && routeMsg.name === 'user',
                }"
                v-for="(item, index) in menuList"
                :key="item.name + index"
                @click.stop="gotoPage(item)"
              >
                <img class="menu-img" :src="item.icon" alt="" />
                <img class="hover-menu-img" :src="item.hoverIcon" alt="" />
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
    </Poptip>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import {User} from "@/service"
export default {
  name: "avatar",
  props: {
    mode: {
      required: true,
      //mode:1 单行头部行高55px mode:两行带搜索,部高度101
      type: Number,
      default: 1,
    },
  },
  watch: {
    "$route.query": function (v) {
      if (this.$route.name === "user") {
        if (v.page) {
          this.getRouteMse();
        }
      }
    },
    getUserInfo:  {
      handler(val) {
        if (val) {
          this.userInfo = val;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      defaultUserImg: require("@/assets/images/user_default.png"),
      menuList: [
        {
          name: "myAccount",
          icon: require("@/assets/images/icon/account@2x.png"),
          hoverIcon: require("@/assets/images/icon/account_hover@2x.png"),
          title: "账号管理",
        },
        {
          name: "myCollect",
          icon: require("@/assets/images/icon/collect@2x.png"),
          hoverIcon: require("@/assets/images/icon/collect_hover@2x.png"),
          title: "我的收藏",
        },
        {
          name: "myClaim",
          icon: require("@/assets/images/icon/claim@2x.png"),
          hoverIcon: require("@/assets/images/icon/claim_hover@2x.png"),
          title: "我的认领",
        },
        {
          name: "myQy",
          icon: require("@/assets/images/icon/qy@2x.png"),
          hoverIcon: require("@/assets/images/icon/qy_hover@2x.png"),
          title: "我的权益",
        },
        {
          name: "quit",
          icon: require("@/assets/images/icon/quit@2x.png"),
          hoverIcon: require("@/assets/images/icon/quit_hover@2x.png"),
          title: "退出登录",
        },
      ],
      showMenu: false,
      stateEnterMenu: false,
      stateEnterAavtar: false,
      closeHandler: null,
      routeMsg: {
        name: "",
        page: "",
      },
      // avatarImg:"",
      // userInfo:{}
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      token:"getToken"
    }),
    isNormal() {
      return this.userInfo?.userMember?.key === "normal";
    },
    isVip() {
      return this.userInfo?.userMember?.key === "vip";
    },
    isSvip() {
      return this.userInfo?.userMember?.key === "svip";
    },
    avatarImg() {
      if (this.userInfo.avatar) {
        return this.userInfo.avatarHost + "/" + this.userInfo.avatar;
      } else {
        return "";
      }
    },
  },
  methods: {
    async getUserInfo() {
      let res = await User.getUserInfo();
      if (res && res.code === 200) {
        this.userInfo = res.data.items;
        if (this.userInfo.avatar) {
          return this.avatarImg =  this.userInfo.avatarHost + "/" + this.userInfo.avatar;
        }
      }
    },
    ...mapMutations(["clearToken", "setUserInfo", "getUserInfo"]),
    openMenu() {
      this.showMenu = true;
      this.stateEnterAavtar = true;
    },
    closeMenu() {
      this.stateEnterAavtar = false;
      this.closeHandler();
    },
    close(time) {
      let bool = false;
      let that = this;
      let timer = null;
      return () => {
        if (bool === true) return;
        bool = true;
        timer = setTimeout(() => {
          if (that.stateEnterMenu === true || this.stateEnterAavtar === true) {
            clearTimeout(timer);
            bool = false;
            return;
          }
          that.showMenu = false;
          clearTimeout(timer);
          bool = false;
        }, time);
      };
    },
    enterMenu() {
      this.stateEnterMenu = true;
    },
    leaveMenu() {
      this.stateEnterMenu = false;
      this.closeHandler();
    },
    gotoPage(item) {
      if (
        item &&
        this.routeMsg.page === item.name &&
        this.routeMsg.name === "user"
      ) {
        return;
      } else if (!item) {
        if (this.routeMsg.name !== "user") {
          this.$router.push({ name: "user", query: { page: "myAccount" } });
        }
        return;
      }
      if (item.name === "myAccount") {
        if (this.routeMsg.name === "user") {
          this.$router.replace({ name: "user", query: { page: "myAccount" } });
        } else {
          this.$router.push({ name: "user", query: { page: "myAccount" } });
        }
      } else if (item.name === "myCollect") {
        if (this.routeMsg.name === "user") {
          this.$router.replace({ name: "user", query: { page: "myCollect" } });
        } else {
          this.$router.push({ name: "user", query: { page: "myCollect" } });
        }
      } else if (item.name === "myClaim") {
        if (this.routeMsg.name === "user") {
          this.$router.replace({ name: "user", query: { page: "myClaim" } });
        } else {
          this.$router.push({ name: "user", query: { page: "myClaim" } });
        }
      } else if (item.name === "myQy") {
        if (this.routeMsg.name === "user") {
          this.$router.replace({ name: "user", query: { page: "myQy" } });
        } else {
          this.$router.push({ name: "user", query: { page: "myQy" } });
        }
      } else if (item.name === "quit") {
        this.$global.confirm({
          title: "提示",
          content: "是否要退出登录？",
          ok: async () => {
            this.quit();
          },
        });
      }
      this.getRouteMse();
    },
    getRouteMse() {
      this.routeMsg.name = this.$route.name;
      if (this.routeMsg.name === "user") {
        if (!this.$route.query.page) {
          this.routeMsg.page = "myAccount";
        } else {
          this.routeMsg.page = this.$route.query.page;
        }
      } else {
        this.routeMsg.page = this.$route.query.page;
      }
    },
    quit() {
      this.clearToken();
      this.setUserInfo({});
      this.$router.replace("index");
    },
  },
  created() {
    if(this.getToken){
      this.getUserInfo();
    }

    this.getRouteMse();
    this.closeHandler = this.close(200);
  },
  mounted() {},
};
</script>
<style lang="less" >
.avatar {
  position: relative;
  .login-user-img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .login-user-img-default {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: block;
    background: url("../../assets/images/user_default.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;
  }
  .user-little-menu-mode2 {
    right: 0;
    left: auto !important;
  }
  .ivu-poptip{
.ivu-poptip-body{
    padding: 0 ;
  }
  }

  .user-little-menu {
    // z-index: 999999;
    width: 200px;
    height: 277px;
    background: rgba(255, 255, 255, 0.99);
    box-shadow: 0px 1px 17px 6px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(16px);
    padding: 20px 0 8px 0;
    box-sizing: border-box;
    // position: absolute;
    // left: -101px;
    // top: 46px;
    cursor: auto;
    .user-msg {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 24px 16px 24px;
      margin-bottom: 13px;
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        opacity: 0.3;
        border: 1px solid rgba(0, 0, 0, 0.4);
        bottom: 0;
        left: 24px;
        right: 24px;
      }
      .user-avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
      }
      .user-normal{
        color: #666;
      }
      .user-normal,
      .user-vip,
      .user-svip {
        margin-left: 12px;
      }
      .user-name {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .menu-list {
      .menu-item {
        cursor: pointer;
        height: 36px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:hover {
          // background: rgba(53, 25, 251, 0.04);
          .menu-img {
            display: none;
          }
          .hover-menu-img {
            display: block;
          }
          span {
            color: #3519fb;
          }
        }
        img {
          width: 14px;
          height: 14px;
        }
        .menu-img {
          display: block;
        }
        .hover-menu-img {
          display: none;
        }
        span {
          margin-left: 8px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 20px;
        }
      }
      .active-menu-item {
        background: rgba(53, 25, 251, 0.04);
        .menu-img {
          display: none;
        }
        .hover-menu-img {
          display: block;
        }
        span {
          color: #3519fb;
        }
      }
    }
  }
}
</style>
