// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/vip-close.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/vip-close-hover.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".custom-model[data-v-7f896f86] {\n  position: relative;\n}\n.custom-model[data-v-7f896f86] .ivu-modal-content {\n  background-color: transparent;\n  box-shadow: none;\n}\n.custom-model[data-v-7f896f86] .ivu-modal-footer,\n.custom-model[data-v-7f896f86] .ivu-modal-close {\n  display: none;\n}\n.custom-model .close-btn[data-v-7f896f86] {\n  display: inline-block;\n  width: 28px;\n  height: 28px;\n  position: absolute;\n  top: 25px;\n  right: -58px;\n  cursor: pointer;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100%;\n}\n.custom-model .close-btn[data-v-7f896f86]:hover {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: 100%;\n}\n.custom-model .btn-group[data-v-7f896f86] {\n  text-align: center;\n  position: absolute;\n  bottom: 47px;\n  left: 181px;\n}\n.custom-model .tab-btn[data-v-7f896f86] {\n  cursor: pointer;\n  width: 236px;\n  height: 40px;\n  line-height: 40px;\n  text-align: center;\n  background: linear-gradient(134deg, #8c92b7 0%, #43496e 100%);\n  box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);\n  border-radius: 4px;\n  color: #faeed8;\n  font-weight: 600;\n  font-size: 16px;\n}\n.custom-model .tab-btn[data-v-7f896f86]:hover {\n  background: linear-gradient(134deg, #707595 0%, #303553 100%);\n  box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);\n}\n.custom-model .tootl-btn[data-v-7f896f86] {\n  cursor: pointer;\n  font-size: 14px;\n  line-height: 20px;\n  color: rgba(255, 255, 255, 0.4);\n  margin-top: 12px;\n}\n.custom-model .tootl-btn[data-v-7f896f86]:hover {\n  color: rgba(255, 255, 255, 0.6);\n}\n", ""]);
// Exports
module.exports = exports;
