<template>
  <div class="edit-pwd" v-if="isOpen">
    <div class="mask"></div>
    <div class="dialog">
      <div class="header">
        <span class="title">{{ title }}</span>
        <img
          class="close-icon"
          src="@/assets/images/icon/close@2x.png"
          alt=""
          @click="close"
        />
      </div>
      <div class="content">
        <div class="item">
          <span class="txt1 block1">中国 +86</span>
          <div class="input-wrap">
            <input
              type="text"
              class="input-phone"
              v-focusblur
              v-model="phone"
              autocomplete="off"
              placeholder="请输入手机号"
            />
            <img
              v-show="phone"
              src="@/assets/images/icon/icon_clear.png"
              alt=""
              class="close-input-icon"
              @click="phone = ''"
            />
          </div>
        </div>
        <div class="item">
          <div class="input-wrap">
            <input
              type="text"
              class="ver-code"
              v-focusblur
              v-model="verCode"
              placeholder="请输入验证码"
            />
            <img
              v-show="verCode"
              src="@/assets/images/icon/icon_clear.png"
              alt=""
              @click="verCode = ''"
              class="close-input-icon"
            />
          </div>
          <span
            class="ver-code-time"
            :class="{
              'no-hover': timer,
              'light-ver-code': phoneRule() && timer === 0,
            }"
            @click="getSmsCodeHandler"
            >{{ hintTxt }}</span
          >
        </div>
        <div class="item">
          <div class="input-wrap">
            <input
              type="password"
              v-show="!stateLookPwd"
              v-focusblur
              v-model="newPwd"
              autocomplete="new-password"
              class="fw-input"
              placeholder="6 - 16 位密码，区分大小写"
            />
            <input
              type="text"
              v-model="newPwd"
              v-focusblur
              v-show="stateLookPwd"
              autocomplete="new-password"
              class="fw-input"
              placeholder="6 - 16 位密码，区分大小写"
            />

            <img
              class="pwd-input-icon"
              @click="lookPwdHandler"
              v-show="!stateLookPwd"
              src="@/assets/images/icon/eye@2x.png"
              alt=""
            />
            <img
              class="pwd-input-icon-hover"
              @click="lookPwdHandler"
              v-show="stateLookPwd"
              src="@/assets/images/icon/eye_hover@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="input-wrap">
            <input
              type="password"
              v-show="!stateNewLookPwd"
              v-focusblur
              v-model="rePwd"
              autocomplete="new-password"
              class="fw-input"
              placeholder="确认密码"
            />
            <input
              type="text"
              v-show="stateNewLookPwd"
              v-focusblur
              v-model="rePwd"
              autocomplete="new-password"
              class="fw-input"
              placeholder="确认密码"
            />

            <img
              class="pwd-input-icon"
              @click="lookNewPwdHandler"
              v-show="!stateNewLookPwd"
              src="@/assets/images/icon/eye@2x.png"
              alt=""
            />
            <img
              class="pwd-input-icon-hover"
              @click="lookNewPwdHandler"
              v-show="stateNewLookPwd"
              src="@/assets/images/icon/eye_hover@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="btn" @click="btnHandler">
          <span class="main-btn">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from "@/service/user";
import { phoneReg, checkPass } from "@/assets/javascript/utils.js";

export default {
  name: "editPwd",
  data() {
    return {
      isOpen: false,
      phone: "",
      title: "",
      newPwd: "",
      rePwd: "",
      verCode: "",
      stateLookPwd: false,
      stateNewLookPwd: false,
      hintTxt: "获取验证码",
      timer: 0,
    };
  },
  methods: {
    lookPwdHandler() {
      this.stateLookPwd = !this.stateLookPwd;
    },
    lookNewPwdHandler() {
      this.stateNewLookPwd = !this.stateNewLookPwd;
    },

    //验证手机短信验证码
    async checkSmsCode(phone, code) {
      const patams = {
        phone: phone,
        code: code,
      };
      let res = await User.checkSmsCaptche(patams);
      if (res && res.code === 200) {
        //修改密码
      } else {
        this.clearTimer();
      }
    },

    //用户点击获取手机验证码事件回调
    getSmsCodeHandler() {
      if (this.timer !== 0) return;
      if (!phoneReg.test(this.phone)) {
        this.$Message.error("请输入正确的手机号码！");
        return;
      }
      if (this.timer === 0) {
        this.getSmsCode(this.phone);
      }
    },

    //获取短信验证码
    async getSmsCode(phone) {
      const params = {
        phone: phone,
      };
      let res = await User.getSmsCaptcha(params);
      if (res && res.code === 200) {
        this.$Message.info("发送成功！");
        this.countDown();
      }
    },
    async resetPwd() {
      const params = {
        phone: this.phone,
        code: this.verCode,
        password: this.newPwd,
        confirmPassword: this.rePwd,
      };
      const res = await User.upDatePwd(params);
      if (res && res.code === 200) {
        this.$Message.info("修改成功");
        this.close();
      }
    },
    //倒计时
    countDown() {
      let countNum = 60;
      this.timer = setInterval(() => {
        countNum -= 1;
        this.hintTxt = `已发送（${countNum}s）`;
        if (countNum <= 0) {
          this.clearTimer();
        }
      }, 1000);
    },
    phoneRule() {
      return phoneReg.test(this.phone);
    },
    //清理定时器
    clearTimer() {
      clearInterval(this.timer);
      this.timer = 0;
      this.hintTxt = "获取验证码";
    },

    initTitle() {
      if (this.mode === "edit") {
        this.title = "修改密码";
      } else {
        this.title = "设置密码";
      }
    },
    btnHandler() {
      if (!phoneReg.test(this.phone)) {
        this.$Message.error("请输入正确的手机号码！");
        return;
      }
      if (!this.verCode) {
        this.$Message.error("请输入验证码！");
        return;
      }
      if (!checkPass.test(this.newPwd)) {
        this.$Message.error("密码格式异常！");
        return;
      }
      if (!this.rePwd) {
        this.$Message.error("请输入确认密码！");
        return;
      }
      if (this.newPwd !== this.rePwd) {
        this.$Message.error("两次密码输入不一致");
        return;
      }
      this.resetPwd();
    },
    close() {
      this.isOpen = false;
      this.clearTimer();
    },
  },
  created() {
    this.initTitle();
  },
  mounted() {},
  destroyed() {
    this.clearTimer();
  },
};
</script>
<style lang="less" scoped>
@import "./edit.less";
.edit-pwd {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
