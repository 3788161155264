<template>
  <div class="edit-phone" v-if="isOpen">
    <div class="mask"></div>
    <div class="dialog">
      <div class="header">
        <span class="title">{{ title }}</span>
        <img
          class="close-icon"
          src="@/assets/images/icon/close@2x.png"
          alt=""
          @click="close"
        />
      </div>
      <div class="content">
        <div class="item" v-show="statusEdit === 1 && mode === 'edit'">
          <span class="txt1">现有手机号：{{ phone }}</span>
        </div>
        <div
          class="item"
          v-show="(statusEdit !== 1 && mode === 'edit') || mode === 'bind'"
        >
          <span class="txt1 block1">中国 +86</span>
          <div class="input-wrap">
            <input type="text" class="input-phone" v-model="newPhone" />
            <img
              v-show="newPhone"
              src="@/assets/images/icon/icon_clear.png"
              alt=""
              class="close-input-icon"
              @click="newPhone = ''"
            />
          </div>
        </div>
        <div class="item">
          <div class="input-wrap">
            <input
              type="text"
              class="ver-code"
              v-model="verCode"
              placeholder="请输入验证码"
            />
            <img
              v-show="verCode"
              src="@/assets/images/icon/icon_clear.png"
              alt=""
              class="close-input-icon"
              @click="verCode = ''"
            />
          </div>
          <span
            class="ver-code-time"
            :class="{ 'no-hover': timer }"
            @click="getSmsCodeHandler"
            >{{ hintTxt }}</span
          >
        </div>
      </div>
      <div class="footer">
        <div class="btn" @click="btnHandler">
          <span class="main-btn" v-show="statusEdit === 1 && mode === 'edit'"
            >下一步</span
          >
          <span
            class="main-btn"
            v-show="(statusEdit !== 1 && mode === 'edit') || mode === 'bind'"
            >确定</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { User } from "@/service/user";
import { phoneReg } from "@/assets/javascript/utils.js";
export default {
  name: "editPhone",
  props: {
    mode: {
      type: String,
      required: true,
      default: "edit", //edit bind 修改或者绑定
    },
    phone: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      statusEdit: 1, //验证手机号状态
      title: "验证手机号",
      newPhone: "",
      verCode: "",
      timer: 0,
      hintTxt: "获取验证码",
    };
  },
  methods: {
    ...mapMutations(["setUserInfoByKey"]),
    btnHandler() {
      if (!this.verCode) {
        this.$Message.error("请输入验证码！");
        return;
      }
      if (this.mode === "edit") {
        if (this.statusEdit === 1) {
          if (this.verCode) {
            this.checkSmsCode(this.phone, this.verCode);
          } else {
            this.$Message.error("请输入短信验证码");
            return;
          }
          /*eslint-disable */
          console.log("验证接口");
        } else {
          /*eslint-disable */
          this.checkSmsCode(this.newPhone, this.verCode);
        }
      } else {
        this.checkSmsCode(this.phone, this.verCode);
      }
    },
    close() {
      this.isOpen = false;
      this.clearTimer();
    },
    initTitle() {
      if (this.mode === "edit") {
        this.title = "验证手机号";
      } else {
        this.title = "绑定手机号";
      }
    },
    //用户点击获取手机验证码事件回调
    getSmsCodeHandler() {
      //1.验证手机号是不是正确
      //2.发送手机验证码
      if (this.timer === 0) {
        if (this.mode === "edit") {
          if (this.statusEdit === 2) {
            if (phoneReg.test(this.newPhone)) {
              this.getSmsCode(this.newPhone);
              return;
            } else {
              this.$Message.error("请输入正确的手机号");
              return;
            }
          }
        }
        this.getSmsCode(this.phone);
      }
    },
    //获取短信验证码
    async getSmsCode(phone) {
      const params = {
        phone: phone,
      };
      let res = await User.getSmsCaptcha(params);
      if (res && res.code === 200) {
        this.$Message.info("发送成功!");
        this.countDown();
      }
    },
    //验证手机短信验证码
    async checkSmsCode(phone, code) {
      const patams = {
        phone: phone,
        code: code,
      };
      let res = await User.checkSmsCaptche(patams);
      if (res && res.code === 200) {
        if (this.mode === "edit") {
          if (this.statusEdit === 1) {
            this.title = "修改手机号";
            this.statusEdit = 2;
            this.verCode = "";
            this.clearTimer();
          } else if (this.statusEdit === 2) {
          }
        } else if (this.mode === "bind") {
        }
      } else {
        this.clearTimer();
      }
    },
    //倒计时
    countDown() {
      let countNum = 60;
      this.timer = setInterval(() => {
        countNum -= 1;
        this.hintTxt = `已发送（${countNum}s）`;
        if (countNum <= 0) {
          this.clearTimer();
        }
      }, 1000);
    },
    //清除倒计时
    clearTimer() {
      clearInterval(this.timer);
      this.timer = 0;
      this.hintTxt = "获取验证码";
    },
  },
  created() {
    this.initTitle();
  },
  mounted() {},
  destroyed() {
    this.clearTimer();
  },
};
</script>
<style lang="less" scoped>
@import "./edit.less";
.edit-phone {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>