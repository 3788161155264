<template>
  <div
    class="loading"
    :class="{ 'full-screen': fullScreen, 'customer-screen': !fullScreen }"
    v-show="visible"
  >
    <!-- <div class="loading-bg"></div> -->
    <div class="loading-content">
      <!-- <div class="ani">
        <div class="outside"></div>
        <div class="inner"></div>
      </div> -->
      <Spin fix></Spin>
      <span>数据加载中。。。</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "loadingCom",
  data() {
    return {
      fullScreen: true,
      visible: false,
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "@/assets/stylesheets/theme.less";
.full-screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}
.customer-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.loading {
  .loading-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 0;
  }
  .loading-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    .ani {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .outside {
        position: absolute;
        width: 40px;
        height: 40px;
        border-width: 3px;
        border-color: @mainColor;
        border-radius: 50%;
        border-left-color: transparent;
        border-style: solid;
        animation: outsideAni 1.5s linear infinite;
      }
      @keyframes outsideAni {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      .inner {
        position: absolute;
        width: 20px;
        height: 20px;
        border-width: 3px;
        border-color: @mainColor;
        border-radius: 50%;
        border-right-color: transparent;
        border-style: solid;
        animation: innerAni 1.5s linear infinite;
      }
      @keyframes innerAni {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0);
        }
      }
    }
    span {
      margin-top: 6px;
      color: #1a1a1a;
      font-size: 16px;
    }
  }
}
</style>
