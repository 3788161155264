var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c("div", { staticClass: "edit-email" }, [
        _c("div", { staticClass: "mask" }),
        _c("div", { staticClass: "dialog" }, [
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("img", {
              staticClass: "close-icon",
              attrs: {
                src: require("@/assets/images/icon/close@2x.png"),
                alt: "",
              },
              on: { click: _vm.close },
            }),
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mode === "bind",
                    expression: "mode === 'bind'",
                  },
                ],
                staticClass: "item",
              },
              [
                _c("div", { staticClass: "input-wrap2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oldEmail,
                        expression: "oldEmail",
                      },
                      { name: "focusblur", rawName: "v-focusblur" },
                    ],
                    staticClass: "fw-input",
                    attrs: { type: "text", placeholder: "请输入邮箱" },
                    domProps: { value: _vm.oldEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.oldEmail = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statusEdit === 1 && _vm.mode === "edit",
                    expression: "statusEdit === 1 && mode === 'edit'",
                  },
                ],
                staticClass: "item",
              },
              [
                _c("span", { staticClass: "txt1" }, [
                  _vm._v("已绑定邮箱：" + _vm._s(_vm.oldEmail)),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statusEdit !== 1 && _vm.mode === "edit",
                    expression: "statusEdit !== 1 && mode === 'edit'",
                  },
                ],
                staticClass: "item",
              },
              [
                _c("div", { staticClass: "input-wrap2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newEmail,
                        expression: "newEmail",
                      },
                      { name: "focusblur", rawName: "v-focusblur" },
                    ],
                    staticClass: "fw-input",
                    attrs: { type: "text", placeholder: "请输入邮箱" },
                    domProps: { value: _vm.newEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.newEmail = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "input-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verCode,
                      expression: "verCode",
                    },
                    { name: "focusblur", rawName: "v-focusblur" },
                  ],
                  staticClass: "ver-code",
                  attrs: { type: "text", placeholder: "请输入邮箱验证码" },
                  domProps: { value: _vm.verCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.verCode = $event.target.value
                    },
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.verCode,
                      expression: "verCode",
                    },
                  ],
                  staticClass: "close-input-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_clear.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.verCode = ""
                    },
                  },
                }),
              ]),
              _c(
                "span",
                {
                  staticClass: "ver-code-time",
                  class: {
                    "no-hover": _vm.timer !== 0,
                    "light-ver-code": _vm.emailRule() && _vm.timer === 0,
                  },
                  on: { click: _vm.getVerCode },
                },
                [_vm._v(_vm._s(_vm.hintTxt))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "btn", on: { click: _vm.btnHandler } }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.statusEdit === 1 && _vm.mode === "edit",
                      expression: "statusEdit === 1 && mode === 'edit'",
                    },
                  ],
                  staticClass: "main-btn",
                },
                [_vm._v("下一步")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        (_vm.statusEdit !== 1 && _vm.mode === "edit") ||
                        _vm.mode === "bind",
                      expression:
                        "(statusEdit !== 1 && mode === 'edit') || mode === 'bind'",
                    },
                  ],
                  staticClass: "main-btn",
                },
                [_vm._v("确定")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }