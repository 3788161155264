var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        [
          _c(
            "Modal",
            {
              attrs: {
                "class-name": "modal-confirm-poptip",
                width: "400",
                closable: false,
                "mask-closable": false,
                "footer-hide": "",
                loading: _vm.isLoading,
              },
              model: {
                value: _vm.isOpen,
                callback: function ($$v) {
                  _vm.isOpen = $$v
                },
                expression: "isOpen",
              },
            },
            [
              _c("div", { staticClass: "remove-control" }, [
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "titleName" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "remove-caption-panel" }, [
                  _c(
                    "div",
                    { staticClass: "remove-text", style: _vm.textStyle },
                    [
                      _vm.content
                        ? _c("p", { style: _vm.contentStyle }, [
                            _vm._v(_vm._s(_vm.content)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "btn-group" },
                  [
                    !_vm.noCancle
                      ? _c(
                          "Button",
                          {
                            staticClass: "cancel-btn",
                            attrs: { disabled: _vm.isLoading },
                            on: { click: _vm.handleClose },
                          },
                          [_vm._v(_vm._s(_vm.cancleText))]
                        )
                      : _vm._e(),
                    _c(
                      "Button",
                      {
                        staticClass: "main-btn",
                        attrs: {
                          disabled: _vm.isLoading,
                          loading: _vm.isLoading,
                          type: "primary",
                        },
                        on: { click: _vm.handleOk },
                      },
                      [_vm._v(_vm._s(_vm.okText))]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }