var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "loading",
      class: {
        "full-screen": _vm.fullScreen,
        "customer-screen": !_vm.fullScreen,
      },
    },
    [
      _c(
        "div",
        { staticClass: "loading-content" },
        [
          _c("Spin", { attrs: { fix: "" } }),
          _c("span", [_vm._v("数据加载中。。。")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }