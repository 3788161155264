<template>
  <div class="page">
    <BrandSearch ref="brandSearch" v-if="!isHome" @getVal="getVal" @getSearchKey="getSearchKey"></BrandSearch>
    <div class="category" v-if="showCategoryList">
      <ul class="category-left">
        <li class="category-title" :class="{ active: curIndex === index }" v-for="(item, index) of brandList" :key="item.code" @mouseover="getCurIndex(index)">
          <img width="22" height="22" :src="item.icon" alt="">
          <span>{{item.name}}</span>
          <Icon size="16" type="ios-arrow-forward" />
        </li>
      </ul>
      <section class="category-right" v-if="brandList.length">
        <dl v-for="(item, index) of brandList[curIndex].node" :key="item.code">
          <dt :key="item.id" :class="{ editable:  index === 0, active: categoryId === item.code}" @click="goBrand(item, index)">{{item.name}}</dt>
          <dd v-for="(sub, subindex) of item.node" :key="sub.code" :class="{ active: categoryId === sub.code}" @click="goBrand(sub)">{{sub.name}}</dd>
        </dl>
      </section>
    </div>
  </div>
</template>

<script>
import { GetBrandTree } from '@/service';
import BrandSearch from "../views/BrandSearch.vue";

export default {
  name: "CategoryComponent",
  data (){
    return {
      brandList: [],
      curIndex: 0,
      categoryId: undefined,
      showCategoryList: true
    }
  },
  components: {
    BrandSearch
  },
  props: {
    isHome: {
      type: Boolean,
      default: false
    },
    isSelectCategory: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSelectCategory: {
      handler (val) {
        if (!this.curIndex || this.categoryId !== val.code) {
          this.getVal(val)
        }
      },
      deep: true
    }
  },
  methods: {
    getSearchKey (val) {
      if (val) {
        this.showCategoryList = false
      } else {
        this.showCategoryList = true
      }
    },
    getVal (val) {
      if (!val.code) {
        this.curIndex = 0
        this.categoryId = undefined
        return
      }
      let select = {}
      if (val.level === 1) {
        const index = this.brandList.findIndex(item => item.code === val.code)
        select = this.brandList[index]
        this.curIndex = index
      } else if (val.level === 2) {
        const [pid, id] = val.code.split('_');
        const pSelectIndex = this.brandList.findIndex(item => item.code === pid)
        this.curIndex = pSelectIndex
        const pSelect = this.brandList[this.curIndex]
        select = pSelect.node.find(item => item.code === val.code)
      } else {
        const [pid] = val.code.split('_');
        const pSelectIndex = this.brandList.findIndex(item => item.code === pid)
        this.curIndex = pSelectIndex
      }
      if (select.node && (val.level === 1 || val.level === 2)) {
        if (select.node.length) {
          this.categoryId = select.node[0].code
          this.$emit('getVal', select.node[0])
        } else {
          this.categoryId = select.code
          this.$emit('getVal', select)
        }
      } else {
        this.categoryId = val.code
        const [pid, id] = val.code.split('_');
        const pSelectIndex = this.brandList.findIndex(item => item.code === pid)
        this.curIndex = pSelectIndex
        const pSelect = this.brandList[this.curIndex]
        select = pSelect.node.find(item => item.code === pid + '_' + id)
        const current = select.node.find(item => item.code === val.code)
        this.$emit('getVal', current)
      }
    },
    clearCategory() {
      this.curIndex = 0
      this.categoryId = undefined
    },
    async getBrandList() {
      const res = await GetBrandTree()
      if (res.code === 200) {
        this.brandList = res.data.items || []
        if (this.categoryId) {
          const [pId, id, sId] = this.categoryId.split('_')
          const pItemIndex = this.brandList.findIndex(item => item.code === pId)
          const pItem = this.brandList[pItemIndex]
          const item = pItem.node.find(i => i.code === pId + '_' + id)
          this.curIndex = pItemIndex
          if (sId) {
            const sItem = item.node.find(i => i.code === this.categoryId)
            this.$emit('getVal', sItem)
            return
          }
          this.$emit('getVal', item)
        }
        // this.$emit('list', this.brandList)
      }
    },
    getCurIndex (index) {
      if (index === this.curIndex) {
        return
      }
      this.curIndex = index
    },
    goBrand (val, index) {
      if (this.isHome) {
        this.$router.push({
          path: '/brand',
          query: {
            id: val.code
          }
        })
      } else {
        if (index) {
          return
        }
        this.categoryId = val.code
        this.$emit('getVal', val)
      }

    },
  },
  created() {
    const ids = this.$route.query.id
    this.categoryId = ids
    this.getBrandList()
  }
}
</script>

<style scoped lang="less">
.page {
  background: rgba(255,255,255,0.98);
  box-shadow: 0 2px 24px 0 rgba(0,0,0,0.15);
  backdrop-filter: blur(4px);
}
.category {
  width: 590px;
  display: flex;
  .category-left {
    height: 400px;
    width: 143px;
    border-right: 1px solid #eee;
    overflow-y: auto;
  }
  .category-title {
    width: 136px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    cursor: pointer;
    position: relative;
    &.active {
      background: rgba(53,25,251,0.06);
      //color: #3519FB;
      ::v-deep .ivu-icon-ios-arrow-forward {
        color: #3519FB;
      }
      &::after {
        display: inline-block;
        content: "";
        height: 20px;
        width: 1px;
        border-right: 2px solid #3519FB;
        position: absolute;
        right: 0;
      }
    }
    ::v-deep .ivu-icon-ios-arrow-forward {
      color: rgba(0, 0, 0, 0.4);
    }
    img {
      margin-right: 8px;
    }
    span {
      margin-right: 25px;
    }
  }
  .category-right {
    font-size: 12px;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    //padding-top: 15px;
    dl {
      width: 398px;
      border-bottom: 1px solid #F2F2F2;
      margin: 0 20px;
      padding: 16px 0;
      white-space: normal;
      &:last-child {
        border-bottom: none;
      }
    }
    dt {
      font-weight: 500;
      color: rgba(0,0,0,0.8);
      &.editable {
        font-weight: normal;
        color: rgba(0,0,0,0.6);
        cursor: pointer;
        &:hover {
          color: #3519FB;
        }
      }
      &.active {
        color: #3519FB;
      }
    }
    dd {
      width: 100px;
      line-height: 22px;
      color: rgba(0,0,0,0.6);
      display: inline-block;
      margin-top: 8px;
      cursor: pointer;
      &.active {
        color: #3519FB;
      }
    }
     dd:hover {
      color: #3519FB;
    }
  }
}
</style>