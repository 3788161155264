module.exports = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/back",
    name: "back",
    component: () => import("../views/back.vue"),
  },
  {
    // 品牌页
    path: "/brand",
    name: "brand",
    component: () => import("../views/brand/Index.vue"),
  },
  {
    //品牌详情
    path: "/brandDetail",
    name: "brandDetail",
    component: () => import("../views/brand/BrandDetails.vue"),
  },
  {
    //门店详情
    path: "/storeDetail",
    name: "storeDetail",
    component: () => import("../views/brand/StoreDetail.vue"),
  },
  {
    // 位置
    path: "/location",
    name: "location",
    component: () => import("../views/location/Index.vue"),
  },
  {
    path: "/vip",
    name: "vip",
    component: () => import("../views/Vip.vue"),
  },
  {
    path: "/mall",
    name: "mall",
    component: () => import("../views/mall/Index.vue"),
  },
  {
    path: "/mallDetail",
    name: "mallDetail",
    component: () => import("../views/mall/MallDetail.vue"),
  },
  {
    path: "/business",
    name: "business",
    component: () => import("../views/business/Index.vue"),
  },
  {
    path: "/businessDetail",
    name: "businessDetail",
    component: () => import("../views/business/BusinessDetail.vue"),
  },
  {
    path: "/city",
    name: "city",
    component: () => import("../views/city/Index.vue"),
  },
  {
    path: "/cityDetail",
    name: "cityDetail",
    component: () => import("../views/city/CityDetail.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/Index.vue"),
  },
  {
    path: "/searchLog",
    name: "searchLog",
    component: () => import("../views/SearchLog.vue"),
  },
];
