import Vue from "vue";
Vue.directive("focusblur", {
  inserted: function (el) {
    el.onfocus = function () {
      el.style.borderColor = "#3519FB";
    };
    el.onblur = function () {
      el.style.borderColor = "#dadada";
    };
  },
});
