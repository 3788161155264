<template>
  <div v-if="isOpen">
    <Modal
      v-model="isOpen"
      class-name="modal-confirm-poptip"
      width="400"
      :closable="false"
      :mask-closable="false"
      footer-hide
      :loading="isLoading"
    >
      <div class="remove-control">
        <div class="title">
          <div class="titleName">
            {{ title }}
          </div>
        </div>
        <div class="remove-caption-panel">
          <div class="remove-text" :style="textStyle">
            <!-- <strong>{{ title }}</strong> -->
            <p v-if="content" :style="contentStyle">{{ content }}</p>
          </div>
        </div>
        <div class="btn-group">
          <Button
            :disabled="isLoading"
            @click="handleClose"
            class="cancel-btn"
            v-if="!noCancle"
            >{{ cancleText }}</Button
          >
          <Button
            :disabled="isLoading"
            :loading="isLoading"
            @click="handleOk"
            type="primary"
            class="main-btn"
            >{{ okText }}</Button
          >
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal } from "view-design";
export default {
  data() {
    return {
      isOpen: false,
      isLoading: false,
      title: "",
      content: "",
      cancleText: "",
      okText: "",
      okFun: null,
      cancleFun: null,
      isWait: false,
      isWaiting: false,
      noCancle: false,
      showType: "",
    };
  },
  computed: {
    textStyle() {
      let obj = {};
      if (!this.content) {
        obj.marginTop = "12px";
      }
      return obj;
    },
    contentStyle() {
      let obj = {};
      if (this.content.length > 17) {
        obj.fontSize = "14px";
      }
      return obj;
    },
  },
  watch: {
    isOpen(newval) {
      if (!newval) {
        this.isLoading = false;
      }
    },
  },
  methods: {
    open(obj) {
      this.title = obj.title || "";
      this.content = obj.content || "";
      this.okFun = obj.ok;
      this.cancleFun = obj.cancle;
      if (!obj.ok && obj.onOk) {
        this.okFun = obj.onOk;
      }
      if (!obj.cancle && obj.onCancle) {
        this.cancleFun = obj.onCancle;
      }
      if (obj.wait) {
        this.isWait = true;
      } else {
        this.isWait = false;
      }
      this.noCancle = obj.noCancle;
      this.showType = obj.type;
      this.okText = "确认";
      if (obj.okText) {
        this.okText = obj.okText;
      } else if (obj.type === "del") {
        this.okText = "删除";
      }
      this.cancleText = obj.cancleText || "取消";
      this.isOpen = true;
    },
    handleClose() {
      if (!this.isWaiting) {
        this.isOpen = false;
        this.cancleFun && this.cancleFun();
      }
    },
    handleOk() {
      this.isLoading = true;
      this.okFun && this.okFun();
      if (!this.isWait) {
        this.isLoading = false;
        this.isOpen = false;
        this.isWaiting = false;
      } else {
        this.isWaiting = true;
      }
    },
  },
  components: {
    Modal,
  },
};
</script>

<style lang="less">
@fontColor: rgba(255, 255, 255, 0.6);
.modal-confirm-poptip {
  display: flex;
  justify-content: center;
  align-items: center;
  .ivu-modal {
    top: 0 !important;
  }
  .ivu-modal .ivu-modal-content {
    width: 400px;
    // height: 306px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    // border-radius: 2px;
    border: 1px solid rgba(56, 76, 108, 0.8);
    backdrop-filter: blur(16px);
  }
  .ivu-modal-body {
    padding: 0;
    overflow: hidden;
    .title {
      color: #333;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
    }
    .remove-caption-panel,
    .copy-caption-panel {
      padding: 15px 0;
      margin: 0 23px;
      overflow: hidden;
      color: #333;
      // border-bottom:1px solid rgba(19, 24, 32, 0.2);
      .left {
        height: 88px;
      }
      .remove-text,
      .remove-folder-text {
        float: left;
        margin-left: 0;
        p {
          margin-top: 4px;
          font-size: 14px;
          color: #333;
        }
      }
      .remove-folder-text {
        p {
          font-size: 14px;
        }
      }
      .copy-text {
        float: left;
        margin-left: 14px;
        margin-top: 12px;
        strong {
          font-size: 20px;
          color: @fontColor;
        }
      }
    }
    .btn-group {
      float: right;
      padding: 15px 24px 24px 0;
      button {
        // min-width: 80px;
        color: #fff;
        border-color: transparent;
        display: inline-block;
        // height: 32px;
        // line-height: 32px;
        padding: 0 20px;
        margin-left: 16px;
        color: #0a90e4;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        background: #3278ff;
        border-radius: 2px;
      }
      .ivu-btn-default {
        border: 1px solid #dadada;
        background: #fff;
        color: #666;
        &:focus {
          box-shadow: none;
        }
        // &:hover {
        //   background: rgba(168, 211, 255, 0.2);
        //   color: #fff;
        // }
      }
      .ivu-btn-primary:hover {
        background: #0a90e5;
      }
      button:first-of-type {
        margin-right: 8px;
      }
    }
  }
}
</style>
