<template>
  <div class="edit-wx" v-if="isOpen">
    <div class="mask"></div>
    <div class="dialog">
      <div class="header">
        <span class="title">绑定微信</span>
        <img
          class="close-icon"
          src="@/assets/images/icon/close@2x.png"
          alt=""
          @click="close"
        />
      </div>
      <div class="content">
        <div class="wx-wrap" id="wx-img"></div>
        <span class="wx-hint">请使用微信扫描二维码完成绑定</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  loadScript,
  getQueryObject,
  delUrlQueryByKey,
} from "../../../assets/javascript/utils";
import { User } from "@/service";
export default {
  name: "editWx",
  props: {
    phone: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      timer: 0,
      callback: null,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
      clearInterval(this.timer);
    },
    getQrCode() {
      // let domain = window.location.href.split("/");
      // const params = {
      //   id: "wx-img",
      //   appId: "wxd357f8ec99f2a8fc",
      //   scope: "snsapi_login",
      //   // redirect_url: window.encodeURI("https://changjing.com.cn"),
      //   redirect_url: "https://account.changjing.com.cn",
      //   state: "",
      //   style: "",
      //   href: ""
      // };
      // let obj = new window.WxLogin(params);
      /* eslint-disable */
      // console.log(obj);
      const backRedirectUrl=this.$config.proxyHost==="/"?"https://qianlimu.com":this.$config.proxyHost
      const redirectUrl =  this.$config.loginUrl+"?operationType=qianlimuPersonalCenter&proName=qianlimu&redirectUrl="+backRedirectUrl+"/user?page=myAccount";
      new WxLogin({
        self_redirect: true,
        id: "wx-img",
        appid: this.$config.appId,
        scope: "snsapi_login",
        redirect_uri: redirectUrl,
        state: "",
        style: "",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgYm9yZGVyOiBub25lOwogIHdpZHRoOiAxNTJweDsKICBoZWlnaHQ6IDE0OXB4OwptYXJnaW4tdG9wOjBweDsKfQouaW1wb3dlckJveCAuc3RhdHVzewogIGRpc3BsYXk6IG5vbmUKfQ==",
      });
    },
    getCode() {
      this.$nextTick().then(() => {
        this.timer = setInterval(() => {
          let contentEle = document.getElementById("wx-img");
          let iframe = contentEle.firstChild;
          let queryObj = getQueryObject(iframe.src);
          /*eslint-disable */
          console.log("code", queryObj.code);
          if (queryObj.code) {
            contentEle.style.visibility = "hidden";
            delUrlQueryByKey("code");
            clearInterval(this.timer);
            this.bindWx(queryObj.code);
          }
        }, 1000);
      });
    },
    async bindWx(code) {
      const params = {
        code: code,
        phone: this.phone,
        product: "qianlimu",
      };
      const res = await User.bindLoginWx(params);
      if (res && res.code === 200) {
        this.$Message.info("绑定成功");
        if (this.callback) {
          this.callback(res.data.items);
        }
        this.close();
      }
    },
  },
  beforeCreate() {
    if (!window.WxLogin) {
      loadScript(this.$config.serverUrls.wxSDKLoginUrl);
    }
  },
  created() {},
  mounted() {
    let that = this;
    this.$nextTick().then(() => {
      that.getQrCode();
      that.getCode();
    });
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
@import "./edit.less";
.edit-wx {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
