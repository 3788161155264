var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      !_vm.isHome
        ? _c("BrandSearch", {
            ref: "brandSearch",
            on: { getVal: _vm.getVal, getSearchKey: _vm.getSearchKey },
          })
        : _vm._e(),
      _vm.showCategoryList
        ? _c("div", { staticClass: "category" }, [
            _c(
              "ul",
              { staticClass: "category-left" },
              _vm._l(_vm.brandList, function (item, index) {
                return _c(
                  "li",
                  {
                    key: item.code,
                    staticClass: "category-title",
                    class: { active: _vm.curIndex === index },
                    on: {
                      mouseover: function ($event) {
                        return _vm.getCurIndex(index)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "22",
                        height: "22",
                        src: item.icon,
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    _c("Icon", {
                      attrs: { size: "16", type: "ios-arrow-forward" },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm.brandList.length
              ? _c(
                  "section",
                  { staticClass: "category-right" },
                  _vm._l(
                    _vm.brandList[_vm.curIndex].node,
                    function (item, index) {
                      return _c(
                        "dl",
                        { key: item.code },
                        [
                          _c(
                            "dt",
                            {
                              key: item.id,
                              class: {
                                editable: index === 0,
                                active: _vm.categoryId === item.code,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goBrand(item, index)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._l(item.node, function (sub, subindex) {
                            return _c(
                              "dd",
                              {
                                key: sub.code,
                                class: { active: _vm.categoryId === sub.code },
                                on: {
                                  click: function ($event) {
                                    return _vm.goBrand(sub)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(sub.name))]
                            )
                          }),
                        ],
                        2
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }