var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c("div", { staticClass: "edit-wx" }, [
        _c("div", { staticClass: "mask" }),
        _c("div", { staticClass: "dialog" }, [
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "title" }, [_vm._v("绑定微信")]),
            _c("img", {
              staticClass: "close-icon",
              attrs: {
                src: require("@/assets/images/icon/close@2x.png"),
                alt: "",
              },
              on: { click: _vm.close },
            }),
          ]),
          _vm._m(0),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "wx-wrap", attrs: { id: "wx-img" } }),
      _c("span", { staticClass: "wx-hint" }, [
        _vm._v("请使用微信扫描二维码完成绑定"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }