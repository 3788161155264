import Vue from "vue";
import "view-design/dist/styles/iview.css";
import ViewUI from "view-design";
Vue.use(ViewUI);

// import {
//   Button,
//   Input,
//   Icon,
//   Poptip,
//   AutoComplete,
//   Select,
//   Option,
//   Dropdown,
//   DropdownItem,
// } from "view-design";
// let list = [
//   Button,
//   Input,
//   Icon,
//   Poptip,
//   AutoComplete,
//   Select,
//   Option,
//   Dropdown,
//   DropdownItem,
// ];
// list.forEach((component) => {
//   Vue.component(component.name, component);
// });
