var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header-wrap",
      class: {
        "showdow-ss header-wrap-height-mode2": _vm.mode === 2,
        "header-wrap-height-mode1 header-wrap-mode1": _vm.mode === 1,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "header-top",
          class: {
            "header-top-mode1": _vm.mode === 1,
            "header-top-mode2": _vm.mode === 2,
          },
        },
        [
          _c("div", { staticClass: "logo-wrap", on: { click: _vm.toHome } }, [
            _c("img", {
              staticClass: "logo logo-white",
              attrs: {
                src: require("../assets/images/logo-white.png"),
                alt: "",
              },
            }),
            _c("img", {
              staticClass: "logo logo-blue",
              attrs: { src: require("../assets/images/logo.png"), alt: "" },
            }),
            _c("span", { staticClass: "beta-icon" }),
          ]),
          _vm.mode === 2
            ? _c(
                "div",
                { staticClass: "header-search-panel" },
                [
                  _c("SearchPanel", {
                    attrs: {
                      cate: _vm.curMenuCate,
                      searchWords: _vm.searchKey,
                      placeholder: _vm.placeholder,
                      cityCode: _vm.cityCode,
                    },
                    on: { search: _vm.search },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.mode === 1
            ? _c(
                "div",
                { staticClass: "header-menus" },
                _vm._l(_vm.menus, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "menu_" + index,
                      class: { cur: _vm.curMenuCate === item.cate },
                      on: {
                        click: function ($event) {
                          return _vm.gotoPath(item.path)
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          class: {
                            "header-menus-color-mode1": _vm.mode === 1,
                            "header-menus-color-mode2": _vm.mode === 2,
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.getToken(),
                  expression: "!getToken()",
                },
              ],
              staticClass: "login-btns",
            },
            [
              _c("div", { staticClass: "header-toolbar" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      on: {
                        mouseenter: _vm.openMenu,
                        mouseleave: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeMenu.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.mode === 1 && !_vm.isRevertIcon
                        ? [
                            _vm.navBarFixed
                              ? [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/icon/relation-app-revert-icon.png"),
                                      width: "30",
                                      height: "30",
                                    },
                                  }),
                                  _c("span", [_vm._v("应用")]),
                                ]
                              : [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/icon/relation-app-icon.png"),
                                      width: "30",
                                      height: "30",
                                    },
                                  }),
                                  _c("span", [_vm._v("应用")]),
                                ],
                          ]
                        : _vm._e(),
                      _vm.mode === 2 || _vm.isRevertIcon
                        ? [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icon/relation-app-revert-icon.png"),
                                width: "30",
                                height: "30",
                              },
                            }),
                            _c("span", [_vm._v("应用")]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.visibleRelationApp,
                          expression: "visibleRelationApp",
                        },
                      ],
                      staticClass: "relation-app",
                      on: {
                        mouseenter: _vm.openMenu,
                        mouseleave: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeMenu.apply(null, arguments)
                        },
                      },
                    },
                    _vm._l(_vm.relationList, function (item) {
                      return _c("section", { key: item.title }, [
                        _c("h3", [_vm._v(_vm._s(item.title))]),
                        _c(
                          "div",
                          { staticClass: "layout-flex" },
                          _vm._l(item.data, function (itm) {
                            return _c(
                              "figure",
                              {
                                key: itm.title,
                                on: {
                                  click: function ($event) {
                                    return _vm.goToTargetLink(itm)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: itm.icon,
                                    width: "36",
                                    height: "36",
                                  },
                                }),
                                _c("figcaption", [
                                  _c("h4", [_vm._v(_vm._s(itm.title))]),
                                  _c("p", [_vm._v(_vm._s(itm.description))]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "span",
                { staticClass: "login-btn", on: { click: _vm.doLogin } },
                [_vm._v("登录")]
              ),
              _vm._v("/"),
              _c(
                "span",
                { staticClass: "register-btn", on: { click: _vm.doLogin } },
                [_vm._v("注册")]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getToken(),
                  expression: "getToken()",
                },
              ],
              staticClass: "login-btns g-aleady-login",
            },
            [
              _c("div", { staticClass: "header-toolbar" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      on: {
                        mouseenter: _vm.openMenu,
                        mouseleave: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeMenu.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.mode === 1 && !_vm.isRevertIcon
                        ? [
                            _vm.navBarFixed
                              ? [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/icon/relation-app-revert-icon.png"),
                                      width: "30",
                                      height: "30",
                                    },
                                  }),
                                  _c("span", [_vm._v("应用")]),
                                ]
                              : [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/icon/relation-app-icon.png"),
                                      width: "30",
                                      height: "30",
                                    },
                                  }),
                                  _c("span", [_vm._v("应用")]),
                                ],
                          ]
                        : _vm._e(),
                      _vm.mode === 2 || _vm.isRevertIcon
                        ? [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icon/relation-app-revert-icon.png"),
                                width: "30",
                                height: "30",
                              },
                            }),
                            _c("span", [_vm._v("应用")]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.visibleRelationApp,
                          expression: "visibleRelationApp",
                        },
                      ],
                      staticClass: "relation-app",
                      on: {
                        mouseenter: _vm.openMenu,
                        mouseleave: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeMenu.apply(null, arguments)
                        },
                      },
                    },
                    _vm._l(_vm.relationList, function (item) {
                      return _c("section", { key: item.title }, [
                        _c("h3", [_vm._v(_vm._s(item.title))]),
                        _c(
                          "div",
                          { staticClass: "layout-flex" },
                          _vm._l(item.data, function (itm) {
                            return _c(
                              "figure",
                              {
                                key: itm.title,
                                on: {
                                  click: function ($event) {
                                    return _vm.goToTargetLink(itm)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: itm.icon,
                                    width: "36",
                                    height: "36",
                                  },
                                }),
                                _c("figcaption", [
                                  _c("h4", [_vm._v(_vm._s(itm.title))]),
                                  _c("p", [_vm._v(_vm._s(itm.description))]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c("Avatar", { attrs: { mode: _vm.mode } }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === 2,
              expression: "mode === 2",
            },
          ],
          staticClass: "header-menus header-menus-navs",
        },
        [
          _c(
            "div",
            { staticClass: "menus-navs" },
            _vm._l(_vm.menus, function (item, index) {
              return _c(
                "div",
                {
                  key: "menu_" + index,
                  class: { cur: _vm.curMenuCate === item.cate },
                  on: {
                    click: function ($event) {
                      return _vm.gotoPath(item.path)
                    },
                  },
                },
                [
                  _c("a", { staticClass: "header-menus-color-mode2" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "data-update-time" }, [
            _vm._v("数据更新日期: " + _vm._s(_vm.yesterday)),
          ]),
        ]
      ),
      _vm.isShowDate
        ? _c("DatePicker", {
            staticClass: "header-date-serch",
            staticStyle: { width: "240px" },
            attrs: {
              type: "daterange",
              placeholder: "按日期范围筛选",
              options: _vm.dateOptions,
              placement: "bottom-end",
              separator: " 至 ",
            },
            on: { "on-change": _vm.changeDaterange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }